import * as React from 'react';
import { Loader } from 'components/loader';
import { IEngineer, SkillLevel, ISkillCategory } from 'models';
import '../engineers/engineer.scss';
import { Card, CardHeader, CardContent } from '../card';
import { IModalCardHeaderToolbarItem } from '../card/CardHeader';
import { StackedLineChart } from './stacked-line-chart';
import { getSkillLevel, SkillLevelTextType, getSkillLevelText } from 'business-area-functions/cdna';
import { MaterialIconType } from 'routes/material-icon/material-icon-type';
import { translate } from 'translations/translation-utils';
import { injectIntl } from 'react-intl';

export interface IEngineerOverviewProps {
    engineer: IEngineer;
    skillCategories: ISkillCategory[];
    isLoading: boolean;
    isOpen: boolean;
    qualificationCardType: string;
    onToggleCategory: (id: string) => void;
    intl: any;
}

class EngineerOverviewClass extends React.Component<IEngineerOverviewProps> {
    public toolbarItems = (): IModalCardHeaderToolbarItem[] => [
        {
            type: 'icon',
            action: this.props.onToggleCategory,
            icon: this.props.isOpen ? MaterialIconType.AngleUp : MaterialIconType.AngleDown,
            label: this.props.isOpen ? 'Expand' : 'Collapse',
        },
    ];

    public getSkills = () =>
        this.props.engineer.skills.filter(
            skill =>
                (skill.isIncluded === null || skill.isIncluded === true) &&
                this.props.skillCategories
                    .find(x => x.id === skill.skillCategoryId)
                    ?.qualificationCardType === this.props.qualificationCardType
        );

    public getTotals = (skillLevel: SkillLevel) =>
        this.getSkills().filter(x => x.skillLevel === skillLevel).length;

    public getData = () => {
        const skills = this.getSkills();
        const c = skills
            .filter((x) => x.skillLevel === SkillLevel.C && x.status)
            .map((y) => ({ skillCategoryId: y.skillCategoryId }));
        const cTotals =
            this.props.skillCategories &&
            this.props.skillCategories.map((x) =>
                c.some((s) => s.skillCategoryId === x.id)
                    ? c.filter((f) => f.skillCategoryId === x.id).length
                    : 0
            );
        const b = skills
            .filter((x) => x.skillLevel === SkillLevel.B && x.status)
            .map((y) => ({ skillCategoryId: y.skillCategoryId }));
        const bTotals =
            this.props.skillCategories &&
            this.props.skillCategories.map((x) =>
                b.some((s) => s.skillCategoryId === x.id)
                    ? b.filter((f) => f.skillCategoryId === x.id).length
                    : 0
            );
        const a = skills
            .filter((x) => x.skillLevel === SkillLevel.A && x.status)
            .map((y) => ({ skillCategoryId: y.skillCategoryId }));
        const aTotals =
            this.props.skillCategories &&
            this.props.skillCategories.map((x) =>
                a.some((s) => s.skillCategoryId === x.id)
                    ? a.filter((f) => f.skillCategoryId === x.id).length
                    : 0
            );
        const dataPoints = [
            { label: 'C', fill: true, backgroundColor: '#17E88F', data: cTotals, pointHitRadius: 20 },
            { label: 'B', fill: true, backgroundColor: '#538184', data: bTotals, pointHitRadius: 20 },
            { label: 'A', fill: true, backgroundColor: '#003F2D', data: aTotals, pointHitRadius: 20 },
        ];
        return dataPoints;
    };

    public getLabels = () => {
        const label = [];
        this.props.skillCategories
            .filter((item) => item.isActive === true)
            .map((skill) => {
                label.push([skill.code, ...skill.name.split(' ')]);
            });
        return label;
    };
    public getDataObject = () => {
        this.getLabels();
        const data = {
            labels: this.getLabels(),
            datasets: this.getData(),
        };
        return data;
    };
    public render() {
        const props = this.props;
        const skillLevel = getSkillLevel(this.getSkills());
        const data = this.getDataObject();
        return (
            <Loader className="EngineerSkills" loading={props.isLoading}>
                {props.engineer ? (
                    <React.Fragment>
                        <Card>
                            <CardHeader
                                title={translate(this.props.intl, 'Engineer.EngineerDNAOverview.DNAOverview')}
                                widgetId="Overview"
                                toolbarItems={this.toolbarItems()}
                            />
                            {this.props.isOpen && (
                                <CardContent>
                                    <div className="columns">
                                        <span className="column is-10">
                                            <StackedLineChart data={data} />
                                        </span>
                                        <span
                                            className="column is-2"
                                            style={{
                                                display: 'flex',
                                                flexFlow: 'column nowrap',
                                                justifyContent: 'flex-start',
                                            }}
                                        >
                                            <div style={{ margin: '0 auto' }}>
                                                <span
                                                    className="engineer-a"
                                                    style={{
                                                        marginBottom: 15,
                                                        display: 'inline-block',
                                                    }}
                                                >
                                                    {getSkillLevelText(
                                                        SkillLevel.A,
                                                        SkillLevelTextType.Letter
                                                    )}
                                                </span>
                                                <span
                                                    style={{
                                                        display: 'inline-block',
                                                        width: 45,
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    {data.datasets
                                                        .find((l) => l.label === 'A')
                                                        .data.reduce((total, item) => total + item)}
                                                    /{this.getTotals(SkillLevel.A)}
                                                </span>
                                            </div>
                                            <div style={{ margin: '0 auto' }}>
                                                <span
                                                    className="engineer-b"
                                                    style={{ marginBottom: 15 }}
                                                >
                                                    {getSkillLevelText(
                                                        SkillLevel.B,
                                                        SkillLevelTextType.Letter
                                                    )}
                                                </span>
                                                <span
                                                    style={{
                                                        display: 'inline-block',
                                                        width: 45,
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    {data.datasets
                                                        .find((l) => l.label === 'B')
                                                        .data.reduce((total, item) => total + item)}
                                                    /{this.getTotals(SkillLevel.B)}
                                                </span>
                                            </div>
                                            <div style={{ margin: '0 auto' }}>
                                                <span
                                                    className="engineer-c"
                                                    style={{ marginBottom: 15 }}
                                                >
                                                    {getSkillLevelText(
                                                        SkillLevel.C,
                                                        SkillLevelTextType.Letter
                                                    )}
                                                </span>
                                                <span
                                                    style={{
                                                        display: 'inline-block',
                                                        width: 45,
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    {data.datasets
                                                        .find((l) => l.label === 'C')
                                                        .data.reduce((total, item) => total + item)}
                                                    /{this.getTotals(SkillLevel.C)}
                                                </span>
                                            </div>
                                            <div>
                                                <div style={{ textAlign: 'center' }}>
                                                    {translate(this.props.intl, 'Engineer.EngineerDNAOverview.Qualification')}
                                                </div>
                                                <div style={{ textAlign: 'center' }}>Level</div>
                                                <div
                                                    style={{ fontSize: '3em', textAlign: 'center' }}
                                                >
                                                    {getSkillLevelText(
                                                        skillLevel,
                                                        SkillLevelTextType.Number
                                                    )}
                                                </div>
                                                <div style={{ textAlign: 'center' }}>
                                                    {getSkillLevelText(
                                                        skillLevel,
                                                        SkillLevelTextType.CatCertified
                                                    )}
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                </CardContent>
                            )}
                        </Card>
                    </React.Fragment>
                ) : (
                    <p>{translate(this.props.intl, 'CompetencyDnaProcess.Label.16')}</p>
                )}
            </Loader>
        );
    }
}

export const EngineerOverview = injectIntl(EngineerOverviewClass);