import React from 'react';
import { IShift, IShiftEngineerSkill } from 'models/shift';
import { SkillLevel } from 'models';
import { ISkillCategory } from 'models/engineer';
import _ from 'lodash';
import { MaterialIcons } from 'routes/material-icon/material-icon';
import { MaterialIconType } from 'routes/material-icon/material-icon-type';
import useTranslate from 'translations/translation-utils';

interface IProps {
    shift: IShift;
    skillCategories: ISkillCategory[];
}

const getSkillLevel = (
    categoryId: string,
    skillCategoryQualificationCardType: string,
    skills: IShiftEngineerSkill[],
    qualificationCardTypes: string[]
) => {
    const result = <mark className="na">-</mark>;
    if (!qualificationCardTypes?.map(type => type.toLowerCase())
        .includes(skillCategoryQualificationCardType.toLowerCase())) {
        return <mark className="na">NA</mark>;
    }

    if (!skills) {
        return result;
    }

    const found = skills.find(s => s.categoryId === categoryId);
    if (!found) {
        return result;
    }

    if (found.level) {
        const cssClass =
            found.level === SkillLevel.C
                ? 'C'
                : found.level === SkillLevel.B
                    ? 'B'
                    : found.level === SkillLevel.A
                        ? 'A'
                        : '';
        return <mark className={cssClass}>{found.level}</mark>;
    }

    return result;
};

export const ShiftGapsCard: React.FC<IProps> = ({ shift, skillCategories }) => {
    const [isExpand, setIsExpand] = React.useState(false);
    const toggleCategories = () => {
        setIsExpand(!isExpand);
    };
    const translate = useTranslate();

    return (
        <div className="container">
            <div className="card is-fullwidth">
                <header className="card-header">
                    <span onClick={toggleCategories}>
                        <MaterialIcons type={!isExpand ? MaterialIconType.AngleDown : MaterialIconType.AngleUp} />
                    </span>
                    <p className="card-header-title">{!isExpand ? translate('ShiftGaps.Label.1') : translate('ShiftGaps.Label.2')}</p>
                    {skillCategories.map((skillCategory, index) => (
                        <>
                            <p key={index} className={`col skills-category skills-category__${skillCategory.status == '1' ? 'not-comply' : 'comply'} `}>
                                {skillCategory.categoryName}
                            </p>
                        </>
                    ))}
                </header>
                <div className={`card-content ${!isExpand ? 'is-hidden' : ''}`}>
                    <div className="">
                        {shift.engineers.map((engineer, i) => (
                            <div className="content" key={i}>
                                <p className="card-content__title">{`${engineer.firstName} ${engineer.lastName}`}</p>
                                {skillCategories.map((skillCategory, index) => (
                                    <p key={index} className="skill-level col">
                                        {getSkillLevel(
                                            skillCategory.categoryId,
                                            skillCategory.qualificationCardType,
                                            engineer.skills,
                                            engineer.qualificationCardTypes)}
                                    </p>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
