import * as React from 'react';
import Page from 'components/v2/page/Page';
import { RouteComponentProps } from 'react-router';
import { history } from 'routes/App';
import { TabGroup, TabItem, Alert, ButtonWithConfirmDialog } from 'components/v2/components';
import { CermAssessmentTabContainer } from './tabs/assessment/cerm-assessment-tab-container';
import { IMasterProcessStep } from 'models/master-process-step/master-process-step';
import { CermSiteScoreContainer } from 'components/cerm/CermSiteScoreContainer';
import { CermAssessmentOverviewTabContainer } from './tabs/overview/cerm-assessment-overview-tab-container';
import { CermAssessmentSummaryTabContainer } from './tabs/assessment-summary/cerm-assessment-summary-tab-container';
import { CermAssessmentToolbar } from './shared/CermAssessmentToolbar';
import {
    IExpertReviewStatus,
    ExpertReviewType,
    getTitlePrefixGeneric
} from 'models/cerm/cerm-assessment-result';
import { IKeyValue } from 'models';
import { checkPermission, checkPermissions } from 'utils/permission-utils';
import CermAssessmentHistoryTab from './tabs/history/CermAssessmentHistoryTab';
import ECermPage from 'routes/e-cerm/e-cerm-page';
import { HelpModuleContainer } from 'routes/help-module/help-module-container';
import { ModuleType } from 'models/help-module';
import { CermHelpToolbar } from './shared/cerm-help-toolbar';
import { getUserId } from '../../helpers/helpers';
import { Privilege } from 'enums/Privilege';
import {
    getSiteOperatingPlatform,
    ISiteAssessmentSummary,
    ISiteProfile,
} from 'models/site-profile';
import { MultiButtonConfirmDialog } from 'components/v2/dialog/MultiButtonConfirmDialog';
import useTranslate from 'translations/translation-utils';
import { IOperatingPlatform } from 'models/operating-platform-model';

enum Tab {
    overview = 'overview',
    assessment = 'assessment',
    history = 'history',
    runbooks = 'runbooks',
    ecerm = 'ecerm',
    assessmentSummary = 'assessment-summary',
}

interface IParams {
    tab: string;
}

interface IProps extends RouteComponentProps<IParams> {
    siteId: string;
    isLoading?: boolean;
    expertReviewStatus: IExpertReviewStatus;
    masterProcessSteps: IMasterProcessStep[];
    loadMasterProcessSteps: (operatingPlatform: string) => void;
    loadCermAssessmentResult(siteId: string);
    startCermExpertReview: (siteId: string, reviewType: string) => void;
    finishCermExpertReview: (siteId: string) => void;
    permissions: Array<IKeyValue<string>>;
    siteName: string;
    showHelpModule: boolean;
    site: ISiteProfile;
    lookupSites: IKeyValue<string>[];
    loadSite: (siteId: string) => void;
    resetSite: (siteId: string) => void;
    updateAssessmentSummary: (siteId: string, model: Partial<ISiteAssessmentSummary>) => void;
    allOperatingPlatforms: IOperatingPlatform[];
}

export const CermAssessmentPage: React.FC<IProps> = ({
    siteId,
    isLoading,
    match,
    masterProcessSteps,
    loadMasterProcessSteps,
    loadCermAssessmentResult,
    startCermExpertReview,
    expertReviewStatus,
    finishCermExpertReview,
    permissions,
    siteName,
    showHelpModule,
    site,
    lookupSites,
    loadSite,
    resetSite,
    updateAssessmentSummary,
    allOperatingPlatforms,
}) => {
    const translate = useTranslate();
    const [showHelp, setShowHelp] = React.useState(false);
    const [showDialog, setShowDialog] = React.useState(false);
    const currentTab = match.params.tab ? match.params.tab.toLowerCase() : Tab.overview;
    const cermAssessmentPageUrl = '/CermAssessment/';
    const [isCermAssessmentDirty, setIsCermAssessmentDirty] = React.useState(false);
    const [operatingPlatform, setOperatingPlatform] = React.useState(
        getSiteOperatingPlatform(siteId, lookupSites)
    );
    React.useEffect(() => {
        if (operatingPlatform) loadMasterProcessSteps(operatingPlatform);
    }, [loadMasterProcessSteps, operatingPlatform]);

    React.useEffect(() => {
        if (!siteId) {
            return;
        }
        setOperatingPlatform(getSiteOperatingPlatform(siteId, lookupSites));
        loadCermAssessmentResult(siteId);
    }, [siteId]);

    React.useEffect(() => {
        if (currentTab === Tab.assessmentSummary) {
            resetSite(siteId);
            loadSite(siteId);
        }
    }, [siteId, currentTab]);

    const onClickHelp = () => {
        setShowHelp(!showHelp);
    };

    const handleClick = (event: any, tab: Tab) => {
        event.preventDefault();
        history.push(setNavLink(tab));
    };

    const onToggleStartReviewDialog = () => {
        setShowDialog(!showDialog);
    };

    const onStartVirtualReview = () => {
        startCermExpertReview(siteId, ExpertReviewType.Virtual);
        setShowDialog(!showDialog);
    };

    const onStartOnSiteReview = () => {
        startCermExpertReview(siteId, ExpertReviewType.OnSite);
        setShowDialog(!showDialog);
    };

    const onFinishReview = () => {
        finishCermExpertReview(siteId);
    };

    const setNavLink = (selectedTab: Tab) => {
        return match.path.replace(':tab', selectedTab);
    };

    const checkCermAssessmentDirty = (isDirty: boolean) => {
        setIsCermAssessmentDirty(isDirty);
    };

    const renderTab = () => {
        switch (currentTab) {
            case Tab.history:
                return <CermAssessmentHistoryTab id={siteId} />;
            case Tab.ecerm:
                return <ECermPage />;
            case Tab.assessmentSummary:
                return (
                    <CermAssessmentSummaryTabContainer
                        siteId={siteId}
                        site={site}
                        isLoading={isLoading}
                        updateAssessmentSummary={updateAssessmentSummary}
                    />
                );
            case Tab.assessment:
                return (
                    <CermAssessmentTabContainer
                        masterProcessSteps={masterProcessSteps}
                        expertReviewStatus={expertReviewStatus}
                        checkCermAssessmentDirty={checkCermAssessmentDirty}
                    />
                );
            case Tab.overview:
            default:
                return <CermAssessmentOverviewTabContainer />;
        }
    };

    const canFinishExpertReview = (): boolean => {
        return (
            expertReviewStatus.startedById === undefined ||
            expertReviewStatus.startedById === null ||
            (expertReviewStatus.startedById &&
                (expertReviewStatus.startedById === getUserId() ||
                    checkPermission(Privilege.CermAssessmentExpertReviewFinish, permissions)))
        );
    };

    const titlePrefix = getTitlePrefixGeneric(operatingPlatform,allOperatingPlatforms);
    const shouldShowShowConfirmNavigateAwayDialog = isCermAssessmentDirty;

    return (
        <Page
            title={`${titlePrefix} ${translate('SideNavBar.Labels.Assessment')}`}
            className="cerm-assessment-page"
            redirectOnSiteChange={true}
            redirectOnSiteChangeUrl={cermAssessmentPageUrl}
            showConfirmNavigateAwayDialog={shouldShowShowConfirmNavigateAwayDialog}
        >
            <MultiButtonConfirmDialog
                title={`${translate('CermAssessment.Lable.3')} ${siteName}`}
                message={`${translate('CermAssessment.Lable.4')} ${translate(
                    'CermAssessment.Lable.5'
                )} ${titlePrefix} ${translate('CermAssessment.Lable.6')}`}
                buttonOneText={translate('CermAssessment.Lable.7')}
                buttonTwoText={translate('CermAssessment.Lable.8')}
                onClose={onToggleStartReviewDialog}
                isVisible={showDialog}
                onButtonOneClick={onStartVirtualReview}
                onButtonTwoClick={onStartOnSiteReview}
                onOutsideDialogClick={onToggleStartReviewDialog}
            />

            {match.params.tab === Tab.assessment &&
                expertReviewStatus &&
                expertReviewStatus.isInReview &&
                checkPermissions(
                    [
                        Privilege.CermAssessmentExpertReviewCreate,
                        Privilege.CermAssessmentExpertReviewFinish,
                    ],
                    permissions
                ) && (
                    <Alert type="warning">
                        <p>
                            {translate('CermAssessment.Lable.9')} {titlePrefix}{' '}
                            {translate('CermAssessment.Lable.10')}{' '}
                            {translate('CermAssessment.Lable.11')}{' '}
                            {expertReviewStatus.startDate.toDateString()}
                            {expertReviewStatus.startedByName &&
                                ' ' +
                                    translate('Cerm.SiteScore.Labels.By') +
                                    ' ' +
                                    expertReviewStatus.startedByName +
                                    '.'}
                            {!(
                                expertReviewStatus.startedById === undefined ||
                                expertReviewStatus.startedById == getUserId()
                            ) && translate('CermAssessment.Lable.12')}
                        </p>
                        {canFinishExpertReview() && (
                            <ButtonWithConfirmDialog
                                buttonText={translate('CermAssessment.Lable.13')}
                                title={translate('CermAssessment.Lable.14')}
                                buttonConfirmText={translate('CermAssessment.Lable.15')}
                                onConfirm={onFinishReview}
                                message={translate('CermAssessment.Lable.16')}
                            />
                        )}
                    </Alert>
                )}

            {match.params.tab === Tab.assessment &&
                expertReviewStatus &&
                expertReviewStatus.isInReview &&
                !checkPermissions(
                    [
                        Privilege.CermAssessmentExpertReviewCreate,
                        Privilege.CermAssessmentExpertReviewFinish,
                    ],
                    permissions
                ) && (
                    <Alert type="warning">
                        <p>
                            {titlePrefix} {translate('CermAssessment.Lable.17')}
                        </p>
                    </Alert>
                )}

            <CermSiteScoreContainer />

            {match.params.tab === Tab.assessment && (
                <CermAssessmentToolbar
                    isAlreadyInReview={expertReviewStatus && expertReviewStatus.isInReview}
                    permissions={permissions}
                    isExporting={false}
                    onExport={null}
                    onStartReview={onToggleStartReviewDialog}
                    onClickHelp={onClickHelp}
                    isShowHelp={showHelpModule || true}
                />
            )}

            <CermHelpToolbar onClickHelp={onClickHelp} isShowHelp={showHelpModule || true} />

            <HelpModuleContainer
                isShown={showHelp}
                onClose={onClickHelp}
                moduleType={ModuleType.Assesment}
            />

            <TabGroup>
                <TabItem
                    title={translate('CermAssessment.Lable.18')}
                    tab={Tab.overview}
                    currentTab={currentTab}
                    onClick={handleClick}
                />
                {checkPermission(Privilege.CermAssessmentUpdate, permissions) && (
                    <TabItem
                        title={translate('CermAssessmentSummary.Lable.3')}
                        tab={Tab.assessmentSummary}
                        currentTab={currentTab}
                        onClick={handleClick}
                    />
                )}
                <TabItem
                    title={translate('SideNavBar.Labels.Assessment')}
                    tab={Tab.assessment}
                    currentTab={currentTab}
                    onClick={handleClick}
                />
                <TabItem
                    title={translate('RiskRegisterUpsertPage.Tabs.History')}
                    tab={Tab.history}
                    currentTab={currentTab}
                    onClick={handleClick}
                />
            </TabGroup>
            {renderTab()}
        </Page>
    );
};
