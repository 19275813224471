import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';
import { CermAssessmentSummaryTab } from './cerm-assessment-summary-tab';
import { loadAttachmentsCermAssessment } from 'actions/actions-v2/attachment-action-v2';
const getSnapshots = (state: IRootState) => state.cermAssessmentResultState.snapshots;
const getAttachments = (state: IRootState) => {
    return state.cermAssessmentResultState.attachmentsSite;
};

const mapStateToProps = createSelector(
    getSnapshots,
    getAttachments,
    (snapshots, attachmentsSite) => ({
        snapshots,
        siteImage: attachmentsSite,
    })
);

const mapDispatchToProps = {
    loadAttachments: loadAttachmentsCermAssessment,
};

export const CermAssessmentSummaryTabContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CermAssessmentSummaryTab);
