import React from 'react';
import { Panel, Button } from '../v2/components';
import { ICermAssessmentSnapshot } from 'models/cerm/cerm-assessment-snapshot';
import { history } from 'routes/App';
import Select from 'react-select';
import { formatDateString } from 'utils/date-utils';
import { GetScorePercentage } from 'utils/decimal-utils';
import classNames from 'classnames';
import useTranslate from 'translations/translation-utils';
import { IOperatingPlatform } from 'models/operating-platform-model';
import { getTitlePrefixGeneric } from 'models/cerm/cerm-assessment-result';

interface IProps {
    siteId: string;
    snapshots: ICermAssessmentSnapshot[];
    loadCermSnapshotsOverviewOnly: (siteId: string) => void;
    operatingPlatform: string;
    allOperatingPlatforms: IOperatingPlatform[];
}

const CermAssessmentComparisonPanel: React.FC<IProps> = ({
    siteId,
    snapshots,
    loadCermSnapshotsOverviewOnly,
    operatingPlatform,
    allOperatingPlatforms
}) => {
    const [selectedLeft, setSelectedLeft] = React.useState(null);
    const [selectedRight, setSelectedRight] = React.useState(null);
    const translate = useTranslate();
    const title = translate('Cerm.ComparisonPanel.Labels.CompareCermAssessments');
    const textToReplace = getTitlePrefixGeneric(operatingPlatform,allOperatingPlatforms); 
    const OPtitle =title.replace("CERM", textToReplace); ;

    React.useEffect(() => {
        if (siteId) {
            loadCermSnapshotsOverviewOnly(siteId);
        }
    }, [siteId, loadCermSnapshotsOverviewOnly]);

    const getOptions = (theOtherSelectedItem: any) => {
        const selectedItem = theOtherSelectedItem ? theOtherSelectedItem.value : null;
        return snapshots
            .filter((snapshot) => snapshot.id !== selectedItem)
            .map((snapshot) => {
                let label = (
                    <>
                        {snapshot.dateLabel} - {GetScorePercentage(snapshot.score)}
                    </>
                );
                if (snapshot.isExpertReview) {
                    let expertReviewText = '(' + translate('Cerm.ComparisonPanel.Labels.ExpertReview') + (snapshot.expertReviewStatus?.startedByName ? ' by ' + snapshot.expertReviewStatus?.startedByName : '') + ')'
                    label = <span className="expert-review">{label} {expertReviewText}</span>;
                }

                return {
                    value: snapshot.id,
                    label
                };
            });
    };

    const getComparisonControl = (
        selectedItem: any,
        setSelectedItem: React.Dispatch<any>,
        theOtherSelectedValue: any
    ) => {
        let snapshot: ICermAssessmentSnapshot = null;
        if (selectedItem) {
            snapshot = snapshots.find((s) => s.id === selectedItem.value);
        }

        return (
            <div className="column column-compare">
                <Select
                    isMulti={false}
                    classNamePrefix="dropdown-multi"
                    placeholder={translate('Cerm.ComparisonPanel.Labels.SelectAssessment')}
                    value={selectedItem}
                    onChange={(e) => setSelectedItem(e)}
                    options={getOptions(theOtherSelectedValue)}
                />
                {snapshot && (
                    <div
                        className={classNames(
                            'columns assessment-info',
                            snapshot.isExpertReview && 'assessment-expert'
                        )}
                    >
                        <div className="column is-8">
                            <p className="assessment-title">
                                {snapshot.isExpertReview
                                    ? translate('Cerm.ComparisonPanel.Labels.ExpertReview')
                                    : `${snapshot.dateLabel} ${translate('Cerm.ComparisonPanel.Labels.SelfAssessment')}`}
                            </p>
                            {snapshot.reviewBy && <p>
                                {`${snapshot.isExpertReview ? translate('Cerm.ComparisonPanel.Labels.Reviewed') : translate('Cerm.ComparisonPanel.Labels.LastUpdated')} by ${snapshot.reviewBy
                                    }`}
                            </p>}
                            <p>
                                {translate('RiskRegisterUpsertPage.Approval.Label.RigisterOn')} {formatDateString(snapshot.date)}
                            </p>
                        </div>
                        <div className="column is-4 assessment-score">
                            {translate('Cerm.SiteScore.Labels.Score')}: {GetScorePercentage(snapshot.score)}
                        </div>
                    </div>
                )}
            </div>
        );
    };

    const onCompareButtonClickHandler = () => {
        history.push(
            `/CermAssessment/comparison/${siteId}/${selectedLeft.value}/${selectedRight.value}`
        );
    };

    const shouldEnableCompareButton = (): boolean => {
        return selectedLeft && selectedRight;
    };

    return (
        <Panel
            className="comparison-panel"
            title={OPtitle}
            collapsed={false}
            bodyPadding={true}
        >
            <div className="columns">
                {getComparisonControl(selectedLeft, setSelectedLeft, selectedRight)}
                {getComparisonControl(selectedRight, setSelectedRight, selectedLeft)}
            </div>
            <div className="has-text-centered">
                <Button
                    onClick={onCompareButtonClickHandler}
                    disabled={!shouldEnableCompareButton()}
                >
                    {translate('Cerm.ComparisonPanel.Labels.CompairVersions')}
                </Button>
            </div>
        </Panel>
    );
};

export default CermAssessmentComparisonPanel;
