import React from 'react';
import 'extensions';
import { createBrowserHistory, History } from 'history';
import { Provider } from 'react-redux';
//import { Route, Router } from 'react-router';
import { Redirect, Route, Switch, Router } from 'react-router-dom';
import { ActionPage } from 'routes/actions/views';
import { CalendarContainer } from 'routes/calendar/views/calendar-container';
import { CermOverviewPage } from 'routes/cerm-overview/views';
import { CermSiteOverviewPage } from 'routes/cerm-site-overview/views';
import { ManageRolesPage } from 'routes/manageRoles/views';
import { QuestionSetsPage } from 'routes/questionSets/views';
import { RiskAssessmentPage } from 'routes/risk-assessment/view';
import { SiteProfilePage } from 'routes/siteProfiles/views';
import { SiteRelationshipsPage } from 'routes/siteRelationships/views';
import { UpsertCermProcessStepPage } from 'routes/upsert-cerm-process-step/views/';
import { UpsertSitePage } from 'routes/upsert-site/views/';
import { CermAssessmentsReview } from './cermAssessments/views/cermAssessmentReview';
import { ClientsPage } from './clients/views';
import { CreateQuestionSetPage } from './create-question-set/views';
import { ContactFormPage } from './form/contact-form-page';
import Layout from './layout/Layout';
import PersonProfilePage from './person-profile/person-profile-page';
import Notification from './notifications/notifications-page';
import Questionnaire from './questionnaire/questionnaire-page';
import { SiteFeaturesPage } from './site-features';
import { createStore } from './store';
import SendNotifications from './send-notifications/send-notifications';
import IncidentsPage from './incidents/incidents-page';
import UpsertIncident from './incidents/upsert-incident/upsert-incident-page';
import { EngineersContainer } from './engineers/engineers-container';
import { EngineerContainer } from './engineer/engineer-container';
import { ManageEngineerSkillsContainer } from './manage-engineer-skills/manage-engineer-skills-container';
import { ProtectedRoute } from './protectedRoute';
import { HomePage } from './home-page';
import Dashboard from './dashboard/Dashboard';
import ShiftsPage from './shifts/shifts-page';
import UpsertShiftPage from './shifts/upsert-shift/upsert-shift-page';
import { CompetencyDNAProcessContainer } from './competency-dna-process/competency-dna-process-container';
import { RiskProfileQuestionnaireContainer } from './risk-profile-questionnaire/risk-profile-questionnaire-container';
import { RiskProfileMasterQuestionnaireContainer } from './risk-profile-master-questionnaire/risk-profile-master-questionnaire-container';
import CermProcessOwnerPage from './cerm-process-owners/cerm-process-owner-page';
import CermGlobalOverviewPage from './cerm-global-overview/cerm-global-overview-page';
import { PermissionDeniedPage } from './error-pages/permission-denied-page';
import CriticalSystemSpareUpsertPage from './critical-system-spares/upsert/critical-system-spare-upsert-page';
import CriticalSystemSparesPage from './critical-system-spares/critical-system-spares-page';
import { SiteFeatures } from 'actions/site-feature';
import { PageNotFound } from './page-not-found/PageNotFound';
import { Privilege } from 'enums/Privilege';
import RiskRegisterUpsertPage from './risk-register/upsert/risk-register-upsert-page';
import RiskRegisterClientPage from './risk-register-client/risk-register-client-page';
import RiskRegisterPage from './risk-register/risks-register-page';
import { ClientSitesOverviewContainer } from './clientView/client-sites-overview/client-sites-overview-container';
import { ClientDashboardContainer } from './clientView/client-dashboard/client-dashboard-container';
import ClientRiskRegister from './clientView/client-risk-register/client-risk-register-page';
import { ClientCriticalSparesContainer } from './clientView/client-critical-spares/client-critical-spares-container';
import { ClientBackToAllSitesContainer } from './clientView/client-back-to-all-sites/client-back-to-all-sites-container';
import ActionRegisterPage from './actions/actions-page';
import ActionUpsertPage from './actions/upsert/action-upsert-page';
import { CermAssessmentPageContainer } from './cerm/cerm-assessment-page-container';
import { CermAssessmentProcessStepPageContainer } from './cerm/cerm-assessment-process-step-page-container';
import { CermAssessmentProcessStepOutcomePageContainer } from './cerm/cerm-assessment-process-step-outcome-page-container';
import { CermAssessmentComparisonPageContainer } from './cerm/cerm-assessment-comparison-page-container';
import { GlobalMockDrillsContainer } from './global-mock-drills/global-mock-drills-container';
import { UpsertGlobalMockDrillContainer } from './global-mock-drills/upsert/upsert-global-mock-drill-container';
import { MockDrillsPageContainer } from './mock-drills/mock-drills-page-container';
import { MockDrillUpsertPageContainer } from './mock-drills/upsert/mock-drill-upsert-page-container';
import { MockDrillReportsPageContainer } from './mock-drills/mock-drill-reports-page-container';
import { MockDrillReportUpsertPageContainer } from './mock-drills/upsert/mock-drill-report-upsert-page-container';
import { SiteDocumentsPageContainer } from './site-documents/site-documents-page-container';
import { ShiftHandoverPageContainer } from './shfit-handover/shift-handover-page-container';
import { ShiftHandoverUpsertPageContainer } from './shfit-handover/upsert/shift-handover-upsert-page-container';
import { ProfileProviderContainer } from './profile-provider/profie-provider-container';
import { LogsUploadPageContainer } from './logs-upload/log-upload-page-container';
import AccessDenied from './access-denied/access-denied-page';
import EcermPage from './e-cerm/e-cerm-page';
import LayoutWithoutSideNav from './layout-without-sidenav/Layout';
import MainReport from './quantum-analytics/main-report';
import { RomonetContainer } from './romonet/romonet-container';
import RomonetExecutiveDashboard from './romonet/romonet-executive-dashboard';
import FacilityOperationsSnapshot from './romonet/facility-operations-snapshot';
import ProjectTrackingDashboard from './romonet/project-tracking-dashboard';
import { EngineerReportContainer } from './romonet/engineer-report/engineer-report-container';
import DataReports from './romonet/data-reports';
import DetailedHallReport from './romonet/detailed-hall-report/detailed-hall-report';
import { OpportunityContainer } from './opportunity-questions/opportunity-container';
import { OpportnityQuestionSetsContainer } from './opportunity-questions/opportunity-question-sets/opportunity-question-sets-container';
import { OpportunititySiteInfoContainer } from './opportunity-siteinfo/opportunity-site-info-container';
import { shiftHandoverAdminUpsertPageContainer } from './shfit-handover-admin/shift-handover-admin-upsert-page-container';
import { CalendarDetailsContainer } from './calendar/views/calendar-details/calendar-details-page-container';
import { ClientVerificationContainer } from './client-multifactor/client-multifactor-page-container';
import { ClientDomainRequiredPage } from './client-domains/client-domain-required-page';
import { UpsertEngineerPageContainer } from './engineer/upsert-engineer-page-container';
import CacheBuster, { CacheBusterState } from './cache/cache-buster';
import { FaqsContainer } from './faq/faqs-container';
import { IauditorInspectionsUpsertContainer } from './iauditor-compliance-checklist/iauditor-inspections-upsert-container';
import AccessRedirectPage from './access-redirect/access-redirect';
import SnowRequestRedirectPage from './snow-request-redirect/snow-request-redirect';
import SnowIncidentRedirectPage from './snow-incident-redirect/snow-incident-redirect';
import { BulkReassignPageContainer } from './bulk-reassign/bulk-reassign-page-container';
import { operatingPlatformListPage } from 'components/operating-platform/views/operating-platform-page-container';
import { operatingPlatformEditPage } from 'components/operating-platform/views/operating-platform-edit-page-container';
import SendSegmentData from './segment-data/send-segment-data';
import { shiftHandoverAdminNewUpsertPageContainer } from './shift-handover-operating-platform-admin/shift-handover-operating-platform-admin-upsert-page-container';
import { CompetencyDNAProcessAdminContainer } from './competency-dna-process-admin/competency-dna-process-admin-container';

export const { store } = createStore();
export const history: History = createBrowserHistory();

const App = () => (
    <CacheBuster>
        {(props: CacheBusterState) => {
            if (!props.loading && !props.isLatestVersion) {
                props.refreshCacheAndReload();
            }
            return (
                <Provider store={store}>
                    <ProfileProviderContainer>
                        {/* <BrowserRouter history={history}> */}
                        <Router history={history}>
                            <>
                                <Switch>
                                    <Route path="/Account/:path?" exact={true}>
                                        <SendSegmentData></SendSegmentData>
                                        <LayoutWithoutSideNav>
                                            <Switch>
                                                <Route
                                                    path="/Account/Ecerm"
                                                    component={EcermPage}
                                                />
                                                <Route
                                                    path="/Account/AccessDenied"
                                                    component={AccessDenied}
                                                />
                                                <Route
                                                    path="/Account/ClientEmailVerificationRequired"
                                                    component={ClientVerificationContainer}
                                                />
                                                <Route
                                                    path="/Account/ClientDomainRequired"
                                                    component={ClientDomainRequiredPage}
                                                />
                                            </Switch>
                                        </LayoutWithoutSideNav>
                                    </Route>
                                    <Route>
                                        <Layout>
                                            <SendSegmentData></SendSegmentData>
                                            <Switch>
                                                <Route exact={true} path="/" component={HomePage} />
                                                <Route path="/Dashboard" component={Dashboard} />
                                                <ProtectedRoute
                                                    exact={true}
                                                    path="/Risks/:specialFilter?"
                                                    component={RiskRegisterPage}
                                                    privileges={[Privilege.RiskView]}
                                                />

                                                <Route exact={true} path="/RiskRegister">
                                                    {<Redirect to={'/Risks'} />}
                                                </Route>
                                                <ProtectedRoute
                                                    path="/RiskRegister/:id?/:tab/:siteId?"
                                                    // path="/RiskRegister/:id?/:tab/:siteId?/:macro?"
                                                    component={RiskRegisterUpsertPage}
                                                    privileges={[Privilege.RiskView]}
                                                />
                                                <ProtectedRoute
                                                    exact={true}
                                                    path="/RiskRegisterClient/:tab"
                                                    component={RiskRegisterClientPage}
                                                    privileges={[Privilege.ClientRiskRegister]}
                                                    isClientView={true}
                                                />
                                                <ProtectedRoute
                                                    exact={true}
                                                    path="/RiskRegisterClient"
                                                    component={RiskRegisterClientPage}
                                                    privileges={[Privilege.RiskView]}
                                                    isClientView={true}
                                                />
                                                <ProtectedRoute
                                                    path="/RiskProfileQuestionnaire"
                                                    component={RiskProfileQuestionnaireContainer}
                                                    privileges={[
                                                        Privilege.RiskProfileQuestionnaire,
                                                    ]}
                                                    siteFeatures={[SiteFeatures.RiskProfiles]}
                                                />
                                                <Route
                                                    path="/RiskAssessment"
                                                    component={RiskAssessmentPage}
                                                />
                                                <ProtectedRoute
                                                    exact={true}
                                                    path="/Actions/:specialFilter?"
                                                    component={ActionRegisterPage}
                                                    privileges={[Privilege.ActionView]}
                                                />
                                                <Route exact={true} path="/Action">
                                                    {<Redirect to="/Actions" />}
                                                </Route>
                                                <ProtectedRoute
                                                    path="/Action/:id?/:category?/:tab/:siteId?/"
                                                    component={ActionUpsertPage}
                                                    privileges={[Privilege.ActionView]}
                                                />
                                                <ProtectedRoute
                                                    path="/Action/:id/"
                                                    component={ActionPage}
                                                    privileges={[Privilege.ActionView]}
                                                />
                                                <ProtectedRoute
                                                    exact={true}
                                                    path="/Incidents/:specialFilter?"
                                                    component={IncidentsPage}
                                                    privileges={[Privilege.IncidentView]}
                                                    siteFeatures={[SiteFeatures.Incidents]}
                                                />
                                                <ProtectedRoute
                                                    path="/Incident/:id?/:tab/:siteId?"
                                                    component={UpsertIncident}
                                                    privileges={[Privilege.IncidentView]}
                                                    siteFeatures={[SiteFeatures.Incidents]}
                                                />
                                                <ProtectedRoute
                                                    path="/SiteDocuments"
                                                    component={SiteDocumentsPageContainer}
                                                    privileges={[Privilege.NotRequired]}
                                                    siteFeatures={[SiteFeatures.SiteDocuments]}
                                                />
                                                <Route
                                                    exact={true}
                                                    path="/CermAssessment/:tab?"
                                                    component={CermAssessmentPageContainer}
                                                />
                                                <Route
                                                    exact={true}
                                                    path="/CermAssessment/ProcessStep/:id"
                                                    component={
                                                        CermAssessmentProcessStepPageContainer
                                                    }
                                                />
                                                <Route
                                                    exact={true}
                                                    path="/CermAssessment/ProcessStep/:id/:outcomeId"
                                                    component={
                                                        CermAssessmentProcessStepOutcomePageContainer
                                                    }
                                                />
                                                <Route
                                                    exact={true}
                                                    path="/CermAssessment/comparison/:siteId/:idLeft/:idRight"
                                                    component={
                                                        CermAssessmentComparisonPageContainer
                                                    }
                                                />
                                                <ProtectedRoute
                                                    exact={true}
                                                    path="/MockDrills/:scenarioType?"
                                                    component={MockDrillsPageContainer}
                                                    privileges={[
                                                        Privilege.MockDrillsRead,
                                                        Privilege.MockDrillsReadWrite,
                                                    ]}
                                                    siteFeatures={[
                                                        SiteFeatures.MockDrills,
                                                        SiteFeatures.ClientMockDrills,
                                                    ]}
                                                    isClientView={true}
                                                />
                                                <ProtectedRoute
                                                    path="/MockDrill/:scenarioType/Details/:id?"
                                                    component={MockDrillUpsertPageContainer}
                                                    privileges={[Privilege.MockDrillsReadWrite]}
                                                    siteFeatures={[
                                                        SiteFeatures.MockDrills,
                                                        SiteFeatures.ClientMockDrills,
                                                    ]}
                                                    isClientView={true}
                                                />
                                                <ProtectedRoute
                                                    exact={true}
                                                    path="/MockDrill/:scenarioType/Reports/:mockDrillType/:mockDrillId"
                                                    component={MockDrillReportsPageContainer}
                                                    privileges={[
                                                        Privilege.MockDrillsRead,
                                                        Privilege.MockDrillsReadWrite,
                                                    ]}
                                                    siteFeatures={[
                                                        SiteFeatures.MockDrills,
                                                        SiteFeatures.ClientMockDrills,
                                                    ]}
                                                    isClientView={true}
                                                />
                                                <ProtectedRoute
                                                    path="/MockDrill/:scenarioType/Reports/:mockDrillType/:mockDrillId/:title/:id?/:tab/:siteId?/"
                                                    component={MockDrillReportUpsertPageContainer}
                                                    privileges={[
                                                        Privilege.MockDrillsRead,
                                                        Privilege.MockDrillsReadWrite,
                                                    ]}
                                                    siteFeatures={[
                                                        SiteFeatures.MockDrills,
                                                        SiteFeatures.ClientMockDrills,
                                                    ]}
                                                    isClientView={true}
                                                />
                                                <ProtectedRoute
                                                    path="/Engineers"
                                                    exact={true}
                                                    component={EngineersContainer}
                                                    privileges={[
                                                        Privilege.CDNAView,
                                                        Privilege.CDNAUpdate,
                                                    ]}
                                                    siteFeatures={[SiteFeatures.CDNA]}
                                                />
                                                <ProtectedRoute
                                                    exact={true}
                                                    path="/Engineer/profile/:engineerId?/:tab?"
                                                    component={UpsertEngineerPageContainer}
                                                    privileges={[
                                                        Privilege.CDNAView,
                                                        Privilege.CDNAUpdate,
                                                    ]}
                                                    siteFeatures={[SiteFeatures.CDNA]}
                                                />
                                                <ProtectedRoute
                                                    path="/Engineer/:engineerId?/:tab?"
                                                    component={UpsertEngineerPageContainer}
                                                    privileges={[
                                                        Privilege.CDNAView,
                                                        Privilege.CDNAUpdate,
                                                    ]}
                                                    siteFeatures={[SiteFeatures.CDNA]}
                                                />
                                                <ProtectedRoute
                                                    path="/Engineers/Engineer/:engineerId?/:userId?"
                                                    component={EngineerContainer}
                                                    privileges={[
                                                        Privilege.CDNAView,
                                                        Privilege.CDNAUpdate,
                                                    ]}
                                                    siteFeatures={[SiteFeatures.CDNA]}
                                                />

                                                <Route
                                                    path="/CermSiteOverview"
                                                    component={CermSiteOverviewPage}
                                                />
                                                <Route
                                                    path="/CermAssessment/:id/outcome/:outcomeId?"
                                                    component={CermAssessmentsReview}
                                                />
                                                <ProtectedRoute
                                                    exact={true}
                                                    path="/CermProcessSteps/:operatingPlatform"
                                                    component={CermOverviewPage}
                                                    privileges={[Privilege.ProcessUpdate]}
                                                />
                                                <ProtectedRoute
                                                    path="/CermProcessSteps/:operatingPlatform/:id?/Upsert/:section/:outcomeIndex?"
                                                    component={UpsertCermProcessStepPage}
                                                    privileges={[Privilege.ProcessUpdate]}
                                                />
                                                <Route
                                                    path="/CermProcessOwners"
                                                    component={CermProcessOwnerPage}
                                                />
                                                <ProtectedRoute
                                                    path="/CermGlobalOverview"
                                                    component={CermGlobalOverviewPage}
                                                    privileges={[Privilege.GlobalOverview]}
                                                />
                                                <ProtectedRoute
                                                    exact={true}
                                                    path="/CriticalSystemSpares"
                                                    component={CriticalSystemSparesPage}
                                                    privileges={[Privilege.CriticalSystemsView]}
                                                    siteFeatures={[SiteFeatures.CriticalSpares]}
                                                />
                                                <ProtectedRoute
                                                    path="/CriticalSystemSpare/:id?/:tab"
                                                    component={CriticalSystemSpareUpsertPage}
                                                    privileges={[Privilege.CriticalSystemsView]}
                                                    siteFeatures={[SiteFeatures.CriticalSpares]}
                                                />
                                                <ProtectedRoute
                                                    exact={true}
                                                    path="/Site"
                                                    component={SiteProfilePage}
                                                    privileges={[Privilege.SiteView]}
                                                />
                                                <ProtectedRoute
                                                    path="/Site/Upsert/:id?/form/:section/:buildingIndex?/:energyBillIndex?"
                                                    component={UpsertSitePage}
                                                    privileges={[Privilege.SiteView]}
                                                />
                                                <ProtectedRoute
                                                    exact={true}
                                                    path="/QuestionSets/:operatingPlatform"
                                                    component={QuestionSetsPage}
                                                    privileges={[
                                                        Privilege.RiskQuestionsOverviewUpdate,
                                                    ]}
                                                />
                                                <ProtectedRoute
                                                    path="/QuestionSets/ManageQuestionSets/:id?/Questions/:questionIndex?/:operatingPlatform"
                                                    component={CreateQuestionSetPage}
                                                    privileges={[
                                                        Privilege.RiskQuestionsOverviewUpdate,
                                                    ]}
                                                />
                                                <ProtectedRoute
                                                    path="/Calendar"
                                                    component={CalendarContainer}
                                                    privileges={[Privilege.CalendarUpdate]}
                                                />
                                                <ProtectedRoute
                                                    exact={true}
                                                    path="/OpportunityQuestionnaire"
                                                    component={OpportunititySiteInfoContainer}
                                                    privileges={[
                                                        Privilege.OpportunityQuestionnaireUpdate,
                                                    ]}
                                                />
                                                <ProtectedRoute
                                                    path="/calendar/:id?/details"
                                                    component={CalendarDetailsContainer}
                                                    privileges={[Privilege.CalendarUpdate]}
                                                />
                                                <ProtectedRoute
                                                    exact={true}
                                                    path="/ShiftHandovers"
                                                    component={ShiftHandoverPageContainer}
                                                    privileges={[
                                                        Privilege.ShiftHandover,
                                                        Privilege.ShiftHandoverRead,
                                                    ]}
                                                    siteFeatures={[
                                                        SiteFeatures.CDNA,
                                                        SiteFeatures.ClientShiftHandover,
                                                    ]}
                                                    isClientView={true}
                                                />
                                                <ProtectedRoute
                                                    exact={true}
                                                    path="/ShiftHandoverAdmin/:tab"
                                                    component={
                                                        shiftHandoverAdminUpsertPageContainer
                                                    }
                                                    privileges={[Privilege.ShiftHandoverAdmin]}
                                                    siteFeatures={[SiteFeatures.CDNA]}
                                                />
                                                <ProtectedRoute
                                                    exact={true}
                                                    path="/shift-handover-new-admin/:tab/:operatingPlatform"
                                                    component={
                                                        shiftHandoverAdminNewUpsertPageContainer
                                                    }
                                                    privileges={[Privilege.ShiftHandoverAdminUpdate]}
                                                    siteFeatures={[SiteFeatures.CDNA]}
                                                />
                                                <ProtectedRoute
                                                    path="/ShiftHandovers/:id?/:tab/site/:siteId/:date?/:facilityId?"
                                                    component={ShiftHandoverUpsertPageContainer}
                                                    privileges={[
                                                        Privilege.ShiftHandover,
                                                        Privilege.ShiftHandoverRead,
                                                    ]}
                                                    siteFeatures={[
                                                        SiteFeatures.CDNA,
                                                        SiteFeatures.ClientShiftHandover,
                                                    ]}
                                                    isClientView={true}
                                                />
                                                <ProtectedRoute
                                                    path="/Client"
                                                    component={ClientsPage}
                                                    privileges={[Privilege.ClientView]}
                                                />
                                                <ProtectedRoute
                                                    path="/ManageRole"
                                                    component={ManageRolesPage}
                                                    privileges={[
                                                        Privilege.ManagePeople,
                                                        Privilege.ManageRoles,
                                                    ]}
                                                />
                                                <Route
                                                    path="/ManageRelationships"
                                                    component={SiteRelationshipsPage}
                                                />
                                                <ProtectedRoute
                                                    path="/SiteFeatures"
                                                    component={SiteFeaturesPage}
                                                    privileges={[Privilege.SiteFeatures]}
                                                />
                                                <ProtectedRoute
                                                    path="/operating-platform/create/:tab"
                                                    component={operatingPlatformEditPage}
                                                    privileges={[Privilege.OperatingPlatformUpdate]}
                                                />
                                                <ProtectedRoute
                                                    path="/operating-platform/list"
                                                    component={operatingPlatformListPage}
                                                    privileges={[Privilege.OperatingPlatformUpdate]}
                                                />

                                                <ProtectedRoute
                                                    path="/operating-platform/details/:id/:tab"
                                                    component={operatingPlatformEditPage}
                                                    privileges={[Privilege.OperatingPlatformUpdate]}
                                                />

                                                <ProtectedRoute
                                                    path="/SendNotifications"
                                                    component={SendNotifications}
                                                    privileges={[Privilege.SendManualNotifications]}
                                                />
                                                <Route
                                                    path="/Questionnaire"
                                                    component={Questionnaire}
                                                />
                                                <Route
                                                    path="/ContactForm"
                                                    component={ContactFormPage}
                                                />
                                                <Route
                                                    path="/PersonProfile/:specialParameter?"
                                                    component={PersonProfilePage}
                                                />
                                                <Route
                                                    path="/Notifications"
                                                    component={Notification}
                                                />
                                                <ProtectedRoute
                                                    path="/ManageEngineerSkills"
                                                    component={ManageEngineerSkillsContainer}
                                                    privileges={[Privilege.CompetencyDNAProcess]}
                                                />
                                                <ProtectedRoute
                                                    path="/CompetencyDNAProcess/:qualificationCard?"
                                                    component={CompetencyDNAProcessContainer}
                                                    privileges={[Privilege.CompetencyDNAProcess]}
                                                    siteFeatures={[SiteFeatures.CDNA]}
                                                />
                                                <ProtectedRoute
                                                    path="/CompetencyDNAProcessAdmin/:skillCardId?"
                                                    component={CompetencyDNAProcessAdminContainer}
                                                    privileges={[Privilege.CompetencyDNAProcessAdminView]}
                                                    siteFeatures={[SiteFeatures.CDNA]}
                                                />
                                                <ProtectedRoute
                                                    exact={true}
                                                    path="/Shifts"
                                                    component={ShiftsPage}
                                                    privileges={[
                                                        Privilege.CDNAView,
                                                        Privilege.CDNAUpdate,
                                                    ]}
                                                />
                                                <ProtectedRoute
                                                    path="/Shifts/Details/:id?"
                                                    component={UpsertShiftPage}
                                                    privileges={[Privilege.CDNAUpdate]}
                                                    siteFeatures={[SiteFeatures.CDNA]}
                                                />
                                                <ProtectedRoute
                                                    path="/RiskProfileMasterQuestionnaire/:operatingPlatform"
                                                    component={
                                                        RiskProfileMasterQuestionnaireContainer
                                                    }
                                                    privileges={[
                                                        Privilege.RiskProfileMasterQuestionnaire,
                                                    ]}
                                                />
                                                <Route
                                                    path="/ClientSitesOverview"
                                                    component={ClientSitesOverviewContainer}
                                                />
                                                <Route
                                                    path="/ClientDashboard"
                                                    component={ClientDashboardContainer}
                                                />
                                                <Route
                                                    path="/RiskRegisterClient/:id/:tab/:siteId?"
                                                    component={ClientRiskRegister}
                                                />
                                                <Route
                                                    exact={true}
                                                    path="/ClientCriticalSystemSpares"
                                                    component={CriticalSystemSparesPage}
                                                />
                                                <Route
                                                    exact={true}
                                                    path="/ClientCriticalSystemSpares/:status"
                                                    component={CriticalSystemSparesPage}
                                                />
                                                <Route
                                                    exact={true}
                                                    path="/ClientCriticalSystemSpares/:category/:status"
                                                    component={CriticalSystemSparesPage}
                                                />
                                                <Route
                                                    path="/ClientCriticalSystemSpare/:id/:tab"
                                                    component={ClientCriticalSparesContainer}
                                                />
                                                <Route
                                                    path="/ClientBackToSitesOverview"
                                                    component={ClientBackToAllSitesContainer}
                                                />
                                                <ProtectedRoute
                                                    exact={true}
                                                    path="/GlobalMockDrills/:scenarioType?"
                                                    component={GlobalMockDrillsContainer}
                                                    privileges={[
                                                        Privilege.GlobalMockDrills,
                                                        Privilege.GlobalMockDrillsAddToSites,
                                                    ]}
                                                />
                                                <ProtectedRoute
                                                    path="/GlobalMockDrill/:tab/:id?"
                                                    component={UpsertGlobalMockDrillContainer}
                                                    privileges={[
                                                        Privilege.GlobalMockDrills,
                                                        Privilege.GlobalMockDrillsAddToSites,
                                                    ]}
                                                />
                                                <ProtectedRoute
                                                    path="/LogsUpload"
                                                    component={LogsUploadPageContainer}
                                                    privileges={[Privilege.LogsUpload]}
                                                />
                                                <ProtectedRoute
                                                    path="/BulkAssignment/:siteId?/:id?"
                                                    component={BulkReassignPageContainer}
                                                    privileges={[Privilege.BulkAssignmentUpdate]}
                                                />
                                                <ProtectedRoute
                                                    path="/QuantumAnalytics"
                                                    component={MainReport}
                                                    privileges={[Privilege.ReportTableau]}
                                                />
                                                <ProtectedRoute
                                                    path="/ClientQuantumAnalytics"
                                                    component={MainReport}
                                                    privileges={[Privilege.ReportTableau]}
                                                    siteFeatures={[
                                                        SiteFeatures.ClientQuantumAnalytics,
                                                    ]}
                                                    isClientView={true}
                                                />
                                                <ProtectedRoute
                                                    exact={true}
                                                    path="/Romonet/HomePage"
                                                    component={RomonetContainer}
                                                    privileges={[Privilege.Romonet]}
                                                    siteFeatures={[
                                                        SiteFeatures.RomonetHomePage,
                                                        SiteFeatures.ClientRomonetHomePage,
                                                    ]}
                                                    isClientView={true}
                                                />
                                                <ProtectedRoute
                                                    exact={true}
                                                    path="/Romonet/ExecutiveDashboard"
                                                    component={RomonetExecutiveDashboard}
                                                    privileges={[Privilege.Romonet]}
                                                    siteFeatures={[
                                                        SiteFeatures.RomonetExecutiveDashboard,
                                                        SiteFeatures.ClientRomonetExecutiveDashboard,
                                                    ]}
                                                    isClientView={true}
                                                />
                                                <ProtectedRoute
                                                    exact={true}
                                                    path="/Romonet/FacilityOperationsSnapshot"
                                                    component={FacilityOperationsSnapshot}
                                                    privileges={[Privilege.Romonet]}
                                                    siteFeatures={[
                                                        SiteFeatures.FacilityOperationsSnapshot,
                                                        SiteFeatures.ClientFacilityOperationsSnapshot,
                                                    ]}
                                                    isClientView={true}
                                                />
                                                <ProtectedRoute
                                                    exact={true}
                                                    path="/Romonet/DetailedHallReport"
                                                    component={DetailedHallReport}
                                                    privileges={[Privilege.Romonet]}
                                                    siteFeatures={[
                                                        SiteFeatures.DetailedHallReport,
                                                        SiteFeatures.ClientDetailedHallReport,
                                                    ]}
                                                    isClientView={true}
                                                />
                                                <ProtectedRoute
                                                    exact={true}
                                                    path="/Romonet/ProjectTrackingDashboard"
                                                    component={ProjectTrackingDashboard}
                                                    privileges={[Privilege.Romonet]}
                                                    siteFeatures={[
                                                        SiteFeatures.ProjectTrackingDashboard,
                                                        SiteFeatures.ClientProjectTrackingDashboard,
                                                    ]}
                                                    isClientView={true}
                                                />
                                                <ProtectedRoute
                                                    exact={true}
                                                    path="/Romonet/EngineerReport"
                                                    component={EngineerReportContainer}
                                                    privileges={[Privilege.Romonet]}
                                                    siteFeatures={[
                                                        SiteFeatures.EngineerReport,
                                                        SiteFeatures.ClientEngineerReport,
                                                    ]}
                                                    isClientView={true}
                                                />
                                                <ProtectedRoute
                                                    exact={true}
                                                    path="/Romonet/DataReports"
                                                    component={DataReports}
                                                    privileges={[Privilege.Romonet]}
                                                    siteFeatures={[
                                                        SiteFeatures.DataReports,
                                                        SiteFeatures.ClientDataReports,
                                                    ]}
                                                    isClientView={true}
                                                />
                                                <Route
                                                    exact={true}
                                                    path="/ComplianceCheckList/:cermProcessId/:tab/"
                                                    component={IauditorInspectionsUpsertContainer}
                                                />
                                                <ProtectedRoute
                                                    exact={true}
                                                    path="/Opportunity/:id?"
                                                    component={OpportunityContainer}
                                                    privileges={[Privilege.ManageOpportunity]}
                                                />
                                                <ProtectedRoute
                                                    path="/Opportunities"
                                                    component={OpportnityQuestionSetsContainer}
                                                    privileges={[Privilege.ManageOpportunity]}
                                                />
                                                <Route path="/ECERM" component={EcermPage} />
                                                <Route
                                                    path="/Requests"
                                                    component={SnowRequestRedirectPage}
                                                />
                                                <Route
                                                    path="/issues"
                                                    component={SnowIncidentRedirectPage}
                                                />
                                                <Route
                                                    path="/AccessDenied"
                                                    component={PermissionDeniedPage}
                                                />
                                                <Route path="/FAQS" component={FaqsContainer} />
                                                <Route
                                                    path="/access"
                                                    component={AccessRedirectPage}
                                                />

                                                {/* this must be at the last */}
                                                <Route component={PageNotFound} />
                                            </Switch>
                                        </Layout>
                                    </Route>
                                </Switch>
                            </>
                        </Router>
                        {/* </BrowserRouter> */}
                    </ProfileProviderContainer>
                </Provider>
            );
        }}
    </CacheBuster>
);
export default App;
