import * as React from 'react';
import { Panel, Button } from 'components/v2/components';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import { ICermProgressChartData } from 'models/cerm/cerm-progress-chart';
import { cloneDeep } from 'lodash';
import useTranslate from 'translations/translation-utils';
import { IOperatingPlatform } from 'models/operating-platform-model';
import { getTitlePrefixGeneric } from 'models/cerm/cerm-assessment-result';
Chart.register(...registerables);

interface IProps {
    siteId: string;
    chartData: ICermProgressChartData;
    getChartData: (siteId: string, period: number) => void;
    operatingPlatform: string;
    allOperatingPlatforms: IOperatingPlatform[];
}

export const CermProgressChart: React.FC<IProps> = ({ siteId, chartData, getChartData, operatingPlatform, allOperatingPlatforms }) => {
    const [chartPeriod, setChartPeriod] = React.useState(6);
    const green = 'rgba(0, 104, 77, 1)';
    const orange = 'rgba(233, 131, 16, 1)';
    const red = 'rgba(224, 47, 55, 1)';
    const blue = 'rgba(103, 169, 229, 1)';

    React.useEffect(() => {
        if (!siteId) {
            return;
        }
        getChartData(siteId, chartPeriod);
    }, [siteId, chartPeriod]);

    const buildPointBackgroundColor = (
        defaultColor,
        expertReviewColor,
        totalPeriods,
        expertReviewPeriods
    ) => {
        const array = new Array(totalPeriods);
        for (let index = 0; index < array.length; index++) {
            array[index] = defaultColor;
        }

        expertReviewPeriods.forEach((i) => (array[i] = expertReviewColor));
        return array;
    };

    const buildPointRadius = (expertReviewSize, totalPeriods, expertReviewPeriods) => {
        const array = new Array(totalPeriods);
        for (let index = 0; index < array.length; index++) {
            // array[index] = undefined;
            array[index] = 6;
        }

        expertReviewPeriods.forEach((i) => (array[i] = expertReviewSize));
        return array;
    };


    const translate = useTranslate();
    const buildChartDataObject = (data: ICermProgressChartData) => {
        const pointRadiusArray =
            data != null ? buildPointRadius(10, chartPeriod, data.expertReviewPeriods) : [];

        return {
            labels: data !== null ? data.labels : [],
            datasets: [
                {
                    label: translate('CermSiteOverview.List.Completion'),
                    data: data !== null ? data.datasets[0].data : [],
                    // line props
                    borderColor: green,
                    fill: false,
                    // borderWidth: 3,
                    lineTension: 0,
                    // point props
                    // pointBackgroundColor: green,
                    pointBorderWidth: 0.1,
                    pointHoverRadius: pointRadiusArray,
                    // pointRadius: 8
                    // pointRadius: [undefined, undefined, undefined, undefined, 10, undefined],
                    pointRadius: pointRadiusArray,
                    // borderColor: [red, red, red, red, red, red],
                    // pointBackgroundColor: [green, green, green, green, blue, red],
                    pointBackgroundColor:
                        data != null
                            ? buildPointBackgroundColor(
                                green,
                                blue,
                                chartPeriod,
                                data.expertReviewPeriods
                            )
                            : []
                },
                {
                    label: translate('SideNavBar.Labels.Compliance'),
                    data: data !== null ? data.datasets[1].data : [],
                    // line props
                    borderColor: orange,
                    fill: false,
                    // borderWidth: 3,
                    lineTension: 0,
                    // point props
                    pointBorderWidth: 0.1,
                    pointHoverRadius: pointRadiusArray,
                    pointRadius: pointRadiusArray,
                    // pointBorderColor: green,
                    // pointBackgroundColor: green,
                    pointBackgroundColor:
                        data != null
                            ? buildPointBackgroundColor(
                                orange,
                                blue,
                                chartPeriod,
                                data.expertReviewPeriods
                            )
                            : []
                },
                {
                    label: translate('CermProgressChart.Score'),
                    data: data !== null ? data.datasets[2].data : [],
                    // line props
                    borderColor: red,
                    fill: false,
                    // borderWidth: 3,
                    lineTension: 0,
                    // point props
                    pointBorderWidth: 0.1,
                    pointHoverRadius: pointRadiusArray,
                    pointRadius: pointRadiusArray,
                    // pointBorderColor: green,
                    // pointBackgroundColor: green,
                    pointBackgroundColor:
                        data != null
                            ? buildPointBackgroundColor(
                                red,
                                blue,
                                chartPeriod,
                                data.expertReviewPeriods
                            )
                            : []
                }
            ],
            expertReviewPeriods: data !== null ? data.expertReviewPeriods : []
        };
    };

    const chartOptions: any = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
        },
        animation: {
            duration: 0
        },
        // elements: {
        //     point: {
        //         radius: 6
        //     }
        // },
        scales: {
            y: {
                suggestedMin: 0,
                suggestedMax: 100,
                ticks: {
                    beginAtZero: true,
                    stepSize: 10,
                    callback: (value) => value + '%'
                },
                title: {
                    display: true,
                    text: translate('CermProgressChart.Message2')
                    // padding: 50 // test
                }
            },
            x: {
                title: {
                    text: translate('CermProgressChart.Message'),
                    display: true
                }
            }
        },
        tooltips: {
            callbacks: {
                label: (tooltipItem, chartDataObject) => {
                    const label = chartDataObject.expertReviewPeriods.includes(tooltipItem.index)
                        ? translate('CermProgressChart.ExpertReview') : translate('CermProgressChart.SelfAssess');

                    return `${label} ${Math.round(tooltipItem.yLabel)}%`;
                }
            }
        }
    };

    const onPeriodButtonClickHandler = (period: number) => {
        setChartPeriod(period);
    };

    const getPeriodButtonClassName = (period: number) => {
        return period === chartPeriod ? 'active' : '';
    };

    const getChartToolbar = () => (
        <div className="columns">
            <div className="column column-period">
                <strong>{translate('CermProgressChart.SelectPeriod')}</strong>
                <div className="buttons has-addons">
                    <Button
                        className={getPeriodButtonClassName(6)}
                        onClick={() => onPeriodButtonClickHandler(6)}
                    >
                        {translate('CheckboxFilter.Labels.6months')}
                    </Button>
                    <Button
                        className={getPeriodButtonClassName(12)}
                        onClick={() => onPeriodButtonClickHandler(12)}
                    >
                        {translate('CermProgressChart.12Months')}
                    </Button>
                    <Button
                        className={getPeriodButtonClassName(18)}
                        onClick={() => onPeriodButtonClickHandler(18)}
                    >
                        {translate('CermProgressChart.18Months')}
                    </Button>
                </div>
            </div>
            <div className="column column-legend">
                <strong>{translate('CermProgressChart.Key')}</strong>
                <ul className="chart-legend">
                    <li className="green">{translate('CermSiteOverview.List.Completion')}</li>
                    <li className="orange">{translate('SideNavBar.Labels.Compliance')}</li>
                    <li className="red">{translate('CermSiteOverview.List.Score')}</li>
                </ul>
            </div>
        </div>
    );

    const chartDatasets = React.useMemo(() => buildChartDataObject(cloneDeep(chartData)), [
        chartData
    ]);

    const titlePrefix = getTitlePrefixGeneric(operatingPlatform, allOperatingPlatforms);

    return (
        <Panel
            className="chart-panel"
            key="chart"
            title={`${titlePrefix} ${translate('CermProgressChart.Progress')}`}
            collapsed={false}
            bodyPadding={true}
        >
            <div className="chart-toolbar">{getChartToolbar()}</div>
            <Line
                data={chartDatasets}
                options={chartOptions}
                height={80} />
        </Panel>
    );
};
