import React from 'react';
import { IMasterProcessStep } from 'models/master-process-step/master-process-step';
import { IProcessStepSnapshot } from 'models/cerm/cerm-assessment-snapshot';
import { ToggleButton } from 'components/v2/components';
import { GetPercentage } from 'utils/decimal-utils';
import classNames from 'classnames';
import useTranslate from 'translations/translation-utils';

interface IProps {
    masterProcessStep: IMasterProcessStep;
    processStepLeft: IProcessStepSnapshot;
    processStepRight: IProcessStepSnapshot;
}

export const CermAssessmentSnapshotProcessStepRow: React.FC<IProps> = ({
    masterProcessStep,
    processStepLeft,
    processStepRight
}) => {
    const translate = useTranslate();
    const [expandStepOutcomes, setExpandStepOutcomes] = React.useState(false);
    const notFound = <span className="not-available">-</span>;
    // Temporary disabled this until next requirement
    // const cermAssessmentUrl = '/CermAssessment/ProcessStep';

    const getProcessStepCompliance = (processStep: IProcessStepSnapshot) => {
        if (processStep) {
            return processStep.applicable === false ? (
                <span className="no">{translate('IncidentDetails.Ongoing.No')}</span>
            ) : (
                GetPercentage(processStep.compliance)
            );
        }

        // when new "masterProcessStep" is added - processStep is null/undefined
        // so display "-" for that row
        return notFound;
    };

    const getOutcomeRows = () => {
        return masterProcessStep.expectedOutcomes.map((outcome, i) => {
            const className =
                masterProcessStep.expectedOutcomes.length - 1 === i
                    ? 'outcome outcome-last'
                    : 'outcome';
            return (
                <tr key={i} className={className}>
                    <td>
                        <ToggleButton buttonType="circle" />
                        <span
                        // Temporary disabled this until next requirement
                        // onClick={() =>
                        //     history.push(
                        //         `${cermAssessmentUrl}/${masterProcessStep.id}/${outcome.id}`
                        //     )
                        // }
                        >
                            #{i + 1}: {outcome.title}
                        </span>
                    </td>
                    <td>{getOutcomeAnswer(processStepLeft, outcome.id)}</td>
                    <td>{getOutcomeAnswer(processStepRight, outcome.id)}</td>
                </tr>
            );
        });
    };

    const getFormattedAnswer = (answer?: boolean) => {
        switch (true) {
            case answer === true:
                return <span className="yes">{translate('IncidentDetails.Ongoing.Yes')}</span>;
            case answer === false:
                return <span className="no">{translate('IncidentDetails.Ongoing.No')}</span>;

            default:
                return <span className="not-answer">{translate('CermComparision.Title.3')}</span>;
        }
    };

    const getOutcomeAnswer = (processStepSnapshot: IProcessStepSnapshot, outcomeId: string) => {
        if (processStepSnapshot) {
            if (processStepSnapshot.applicable === false) {
                return <span className="not-applicable">N/A</span>;
            }

            const outcomeFound = processStepSnapshot.outcomes.find(
                (outcome) => outcome.id === outcomeId
            );
            if (outcomeFound) {
                return getFormattedAnswer(outcomeFound.answer);
            }
        }

        // when new "masterProcessStep" is added - processStepSnapshot is null/undefined
        // so display "-" for that row
        return notFound;
    };

    return masterProcessStep ? (
        <>
            <tr className={classNames('process-step', expandStepOutcomes ? 'expanded' : '')}>
                <td>
                    <ToggleButton
                        buttonType="plus-circle"
                        isEnabled={!expandStepOutcomes}
                        onClick={() => setExpandStepOutcomes(!expandStepOutcomes)}
                    />

                    <span
                    // Temporary disabled this until next requirement
                    // onClick={() => history.push(`${cermAssessmentUrl}/${masterProcessStep.id}`)}
                    >
                        {masterProcessStep.processNumber}: {masterProcessStep.title}
                    </span>
                </td>
                <td>{getProcessStepCompliance(processStepLeft)}</td>
                <td>{getProcessStepCompliance(processStepRight)}</td>
            </tr>
            {expandStepOutcomes ? getOutcomeRows() : null}
        </>
    ) : null;
};
