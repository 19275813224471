import * as React from 'react';
import { Link } from 'react-router-dom';
import './Nav.scss';
import { NavItem } from './NavItem';
import iconEcerm from 'assets/icon-ecerm.svg';
import iconRisks from 'assets/icon-risks.svg';
import iconActions from 'assets/icon-actions.svg';
import iconIncidents from 'assets/icon-incidents.svg';
import iconCalendar from 'assets/icon-calendar.svg';
import { INotificationCount } from 'models/notification';
import useTranslate from 'translations/translation-utils';
import { IOperatingPlatform } from 'models/operating-platform-model';

export interface IProps extends React.AllHTMLAttributes<HTMLDivElement> {
    moduleNotificationCount?: INotificationCount[];
    operatingPlatform: IOperatingPlatform;
}
interface INavModule {
    actionCount: number;
    incidentCount: number;
    riskCount: number;
}

export const NavModuleNotification: React.FC<IProps> = props => {
    const translate = useTranslate();
    const initialState = { actionCount: 0, incidentCount: 0, riskCount: 0 };
    const [moduleCount, setModuleCount] = React.useState<INavModule>(initialState);
    const [operatingPlatform, setOperatingPlatform] = React.useState<IOperatingPlatform>(
        props.operatingPlatform
    );

    React.useEffect(() => {
        let count = { ...moduleCount };
        props.moduleNotificationCount &&
            props.moduleNotificationCount.forEach(item => {
                count[item.messageType] = item.message.count;
            });
        setModuleCount({ ...count });
    }, [props.moduleNotificationCount]);

    React.useEffect(() => {
        if (props.operatingPlatform) {
            setOperatingPlatform(props.operatingPlatform);
        }
    }, [props.operatingPlatform]);

    return (
        <NavItem hasContainer={true} hasDropdown={true} className={props.className} link={'/'}>
            <div className="navbar-secondary">
                <Link
                    data-tip={`E${operatingPlatform?.shortForm}`}
                    to={{ pathname: operatingPlatform?.processLink }}
                    target="_blank"
                    className={
                        operatingPlatform?.processLink ? 'navbar-secondary-icon' : 'navitem-hide'
                    }
                >
                    <span className="icon">
                        <img src={iconEcerm} alt={`E${operatingPlatform?.shortForm}`} />
                    </span>
                </Link>

                <Link
                    data-tip={translate('SideNavBar.Labels.Calendar')}
                    to="/Calendar"
                    className="navbar-secondary-icon"
                >
                    <span className="icon">
                        <img src={iconCalendar} alt={translate('SideNavBar.Labels.Calendar')} />
                    </span>
                </Link>

                <Link
                    data-tip={translate('SideNavBar.Labels.Actions')}
                    to={`${
                        moduleCount && moduleCount.actionCount > 0
                            ? '/Actions/AssignedToCurrentUser'
                            : '/Actions'
                    }`}
                    className="navbar-secondary-icon"
                >
                    <span className="icon">
                        <img src={iconActions} alt={translate('SideNavBar.Labels.Actions')} />
                        {moduleCount && moduleCount.actionCount > 0 && (
                            <span className="badge badge-pill badge-warning">
                                {moduleCount && moduleCount.actionCount}
                            </span>
                        )}
                    </span>
                </Link>

                <Link
                    data-tip={translate('RiskRegisterUpsertPage.CustomLinks.Title')}
                    to={`${
                        moduleCount && moduleCount.riskCount > 0
                            ? '/Risks/AssignedToCurrentUser'
                            : '/Risks'
                    }`}
                    className="navbar-secondary-icon "
                >
                    <span className="icon">
                        <img
                            src={iconRisks}
                            alt={translate('RiskRegisterUpsertPage.CustomLinks.Title')}
                            className="navbar-secondary-icon__risks"
                        />
                        {moduleCount && moduleCount.riskCount > 0 && (
                            <span className="badge badge-pill badge-warning">
                                {moduleCount && moduleCount.riskCount}
                            </span>
                        )}
                    </span>
                </Link>

                <Link
                    data-tip={translate('SideNavBar.Labels.Incidents')}
                    to={`${
                        moduleCount && moduleCount.incidentCount > 0
                            ? '/Incidents/AssignedToCurrentUser'
                            : '/Incidents'
                    }`}
                    className={
                        moduleCount && moduleCount.incidentCount
                            ? 'navbar-secondary-icon'
                            : 'navitem-hide'
                    }
                >
                    <span className="icon">
                        <img src={iconIncidents} alt={translate('SideNavBar.Labels.Incidents')} />
                        {moduleCount && moduleCount.incidentCount > 0 && (
                            <span className="badge badge-pill badge-warning">
                                {moduleCount && moduleCount.incidentCount}
                            </span>
                        )}
                    </span>
                </Link>
            </div>
        </NavItem>
    );
};
