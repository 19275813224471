import { connect } from 'react-redux';
import { IRootState } from 'routes/store';
import { createSelector } from 'reselect';
import { loadCermSnapshotsOverviewOnly } from 'actions/actions-v2/cerm-assessment-action-v3';
import CermAssessmentComparisonPanel from './cerm-assessments-comparison-panel';

const getSnapshots = (state: IRootState) => state.cermAssessmentResultState.snapshots;
const getOperatingPlatforms = (state: IRootState) => state.operatingPlatformState.operatingPlatforms;

const mapStateToProps = createSelector(getSnapshots, getOperatingPlatforms,(snapshots,allOperatingPlatforms) => ({
    snapshots,
    allOperatingPlatforms
}));

const mapDispatchToProps = {
    loadCermSnapshotsOverviewOnly
};

export const CermAssessmentComparisonPanelContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CermAssessmentComparisonPanel);
