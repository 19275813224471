import { IKeyValue } from '.';

export enum IncidentSeverity {
    S1 = 'S1',
    S2 = 'S2',
    S3 = 'S3',
    S4 = 'S4',
}
export enum IncidentRootCause {
    EquipmentFailure = 'Equipment Failure',
    HumanError = 'Human Error',
    InadequateProcedure = 'Inadequate Procedure',
    DesignDeficiency = 'Design Deficiency',
    ManufacturerDefect = 'Manufacturer Defect',
    ExternalInfluences = 'External Influences',
    Other = 'Other',
}

export enum ResponsibleParty {
    ClientFacilitiesGroup = 'Client Facilities Group',
    ClientItGroup = 'Client IT Group',
    CbreFmTeam = 'CBRE FM Group',
    CbreEngineeringTeam = 'CBRE Engineering Team',
    SecurityDepartment = 'Security Department',
    CbreVendor = 'CBRE Vendor',
    ClientVendor = 'Client Vendor',
    OriginalEquipmentVendor = 'Original Equipment Vendor',
}

export const incidentSeverities: Array<IKeyValue<string>> = [
    { key: IncidentSeverity.S1, value: 'S1' },
    { key: IncidentSeverity.S2, value: 'S2' },
    { key: IncidentSeverity.S3, value: 'S3' },
    { key: IncidentSeverity.S4, value: 'S4' },
];

export enum IncidentType {
    FullIncident = 'FullIncident',
    NearMiss = 'NearMiss',
}

export const incidentTypes: Array<IKeyValue<string>> = [
    { key: IncidentType.FullIncident, value: 'Incident' },
    { key: IncidentType.NearMiss, value: 'Near Miss' },
];

export enum IncidentStatus {
    Draft = 'Draft',
    Open = 'Open',
    Pending = 'Pending',
    Closed = 'Closed',
}

export enum IncidentApprovalStatus {
    Approved = 'Approved',
    Rejected = 'Rejected',
    Overridden = 'Overridden',
}

export const incidentStatuses: Array<IKeyValue<string>> = [
    { key: IncidentStatus.Draft, value: 'Draft' },
    { key: IncidentStatus.Open, value: 'Open' },
    { key: IncidentStatus.Pending, value: 'Pending' },
    { key: IncidentStatus.Closed, value: 'Closed' },
];

export enum IncidentTimePeriod {
    Months1 = '1 month',
    Months3 = '3 months',
    Months6 = '6 months',
}

export const incidentTimePeriods: Array<IKeyValue<string>> = [
    { key: IncidentTimePeriod.Months1, value: '1 month' },
    { key: IncidentTimePeriod.Months3, value: '3 months' },
    { key: IncidentTimePeriod.Months6, value: '6 months' },
];

export const incidentResponsibleParty: Array<IKeyValue<string>> = [
    {
        label: ResponsibleParty.ClientFacilitiesGroup.toString(),
        value: ResponsibleParty.ClientFacilitiesGroup,
    },
    { label: ResponsibleParty.ClientItGroup.toString(), value: ResponsibleParty.ClientItGroup },
    { label: ResponsibleParty.CbreFmTeam.toString(), value: ResponsibleParty.CbreFmTeam },
    {
        label: ResponsibleParty.CbreEngineeringTeam.toString(),
        value: ResponsibleParty.CbreEngineeringTeam,
    },
    {
        label: ResponsibleParty.SecurityDepartment.toString(),
        value: ResponsibleParty.SecurityDepartment,
    },
    { label: ResponsibleParty.CbreVendor.toString(), value: ResponsibleParty.CbreVendor },
    { label: ResponsibleParty.ClientVendor.toString(), value: ResponsibleParty.ClientVendor },
    {
        label: ResponsibleParty.OriginalEquipmentVendor.toString(),
        value: ResponsibleParty.OriginalEquipmentVendor,
    },
];

export const incidentRootCause: Array<IKeyValue<string>> = [
    {
        key: IncidentRootCause.DesignDeficiency,
        value: IncidentRootCause.DesignDeficiency,
        label: IncidentRootCause.DesignDeficiency.toString(),
    },
    {
        key: IncidentRootCause.EquipmentFailure,
        value: IncidentRootCause.EquipmentFailure,
        label: IncidentRootCause.EquipmentFailure.toString(),
    },
    {
        key: IncidentRootCause.ExternalInfluences,
        value: IncidentRootCause.ExternalInfluences,
        label: IncidentRootCause.ExternalInfluences.toString(),
    },
    {
        key: IncidentRootCause.HumanError,
        value: IncidentRootCause.HumanError,
        label: IncidentRootCause.HumanError.toString(),
    },
    {
        key: IncidentRootCause.InadequateProcedure,
        value: IncidentRootCause.InadequateProcedure,
        label: IncidentRootCause.InadequateProcedure.toString(),
    },
    {
        key: IncidentRootCause.ManufacturerDefect,
        value: IncidentRootCause.ManufacturerDefect,
        label: IncidentRootCause.ManufacturerDefect.toString(),
    },
    {
        key: IncidentRootCause.Other,
        value: IncidentRootCause.Other,
        label: IncidentRootCause.Other.toString(),
    },
];

export enum ContributingFactors {
    BMSAutomation = 'BMS Automation',
    Training = 'Training',
    ChangeControl = 'Change Control',
    Unclear = 'Unclear',
    ContractorRelated = 'Contractor Related',
    WorkPractice = 'Work Practice',
    Equipment = 'Equipment',
    Investigation = 'Investigation',
    Other = 'Other, please specify',
}

export enum IncidentImpact {
    DroppedCriticalLoad = 'Dropped Critical Load',
    ReducedRedundancy = 'Reduced Redundancy',
    Evacuation = 'Evacuation',
    PersonnelInjury = 'Personnel Injury',
    EnvironmentalImpact = 'Environmental Impact',
    UtilityOutage = 'Utility Outage',
    IncidentImpactOther = 'Other, please specify',
}

export enum ImpactedSystem {
    ElectricalDistribution = 'Electrical Distribution',
    UninterruptiblePower = 'Uninterruptible Power',
    DieselGenerator = 'Diesel Generator',
    MechanicalCooling = 'Mechanical/Cooling',
    FireProtection = 'Fire Protection',
    ImpactedSystemOther = 'Other, please specify',
}

export enum ScaleOfImpact {
    Server = 'Server',
    Rack = 'Rack',
    Hall = 'Hall',
    WholeDataCentre = 'Whole Data Centre',
    ScaleOfImpactOther = 'Other, please specify',
}