import * as React from 'react';
import { ISiteAssessmentSummary, ISiteProfile } from 'models/site-profile';
import { IAttachmentModel } from 'models/attachment/attachment-model';
import { ICermAssessmentSnapshot } from 'models/cerm/cerm-assessment-snapshot';
import useTranslate from 'translations/translation-utils';
import { ReadOnlyField } from 'components/form/fields/read-only-field';
import { TextAreaField } from 'components/form/fields/textarea-field';
import { GetPercentage } from 'utils/decimal-utils';
import { FormikProps } from 'formik';

interface IProps {
    site: ISiteProfile;
    snapshots: ICermAssessmentSnapshot[];
    formikBag: FormikProps<ISiteAssessmentSummary>;
    siteImage: IAttachmentModel[];
}

export const CermAssessmentSummaryTabForm: React.FC<IProps> = ({
    site,
    snapshots,
    formikBag,
    ...props
}) => {

    const translate = useTranslate();

    const getMainAddress = () => {
        if (!site.buildings || site.buildings.length === 0) {
            return null;
        }

        const masterAddressBuilding = site.buildings.filter((b) => b.isMasterAddress);
        return masterAddressBuilding && masterAddressBuilding[0];
    };

    const getTierLevels = () => {
        const tierLevels: string[] = [];

        if (site.buildings && site.buildings.length > 0) {
            site.buildings.forEach((building) => {
                if (tierLevels.indexOf(building.tier) === -1) {
                    if (building.tier !== '') {
                        tierLevels.push(building.tier);
                    }
                }
            });
        }

        return tierLevels.join(',');
    };
    const mainAddress = getMainAddress();
    const expertReviews = snapshots.filter((snapshot) => snapshot.isExpertReview)
    const snapshot = expertReviews[expertReviews.length - 1];
    const siteImageUrl = props.siteImage[0] && props.siteImage[0].blobStorageUri;



    return (
        <div className="columns">
            <div className="column is-6">
                <div className="summary-section">
                    <div className="cerm-assessment-summary__site-image">
                        {siteImageUrl ?
                            <img src={siteImageUrl} alt={translate('CermAssessmentSummary.Label.NoImageavailable')} /> :
                            <p><strong>{translate('CermAssessmentSummary.Label.NoImageavailable')} </strong></p>}

                    </div>

                    <div className="cerm-assessment-summary__description">
                        <div className="summary-section">
                            <TextAreaField
                                id="facilityDescription"
                                label={translate('CermAssessmentSummary.Lable.1')}
                                placeholder={translate('CermAssessmentSummary.Lable.2')}
                                rows={10}
                                value={formikBag.values.facilityDescription}
                                error={formikBag.errors.facilityDescription}
                                handleChange={formikBag.handleChange}
                                setVal={formikBag.setFieldValue}
                            />
                        </div>

                        <div className="summary-section">
                            <TextAreaField
                                id="assessmentSummary"
                                label={translate('CermAssessmentSummary.Lable.3')}
                                placeholder={translate('CermAssessmentSummary.Lable.4')}
                                rows={10}
                                value={formikBag.values.assessmentSummary}
                                error={formikBag.errors.assessmentSummary}
                                handleChange={formikBag.handleChange}
                                setVal={formikBag.setFieldValue}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="column is-6">
                <div className="cerm-assessment-summary__site-details">
                    <div className="site-address">
                        <ReadOnlyField
                            label={translate('CermAssessmentSummary.Label.SiteNameandAddress')}
                        >
                            <p>{site.name}</p>
                            <p>{mainAddress && mainAddress.addressLine1}</p>
                            <p>{mainAddress && mainAddress.city}</p>
                            <p>
                                <span>{mainAddress && mainAddress.postCode}</span>
                                <span> {mainAddress && mainAddress.country}</span>
                            </p>
                        </ReadOnlyField>
                    </div>
                    <div className="summary-section">
                        <ReadOnlyField
                            label={translate('CermAssessmentSummary.Label.TierLevel')}
                            value={`Currently-Tier ${getTierLevels()}`}
                        />
                    </div>
                </div>

                <div className="cerm-assessment-summary__details">
                    <div className="summary-section">
                        <TextAreaField
                            id="yearConstructed"
                            label={translate('CermAssessmentSummary.Lable.6')}
                            placeholder={translate('CermAssessmentSummary.Lable.5')}
                            rows={3}
                            value={formikBag.values.yearConstructed}
                            error={formikBag.errors.yearConstructed}
                            handleChange={formikBag.handleChange}
                            setVal={formikBag.setFieldValue}
                        />
                    </div>

                    <div className="summary-section">
                        <TextAreaField
                            id="criticalLinesOfBusiness"
                            label={translate('CermAssessmentSummary.Lable.7')}
                            placeholder={translate('CermAssessmentSummary.Lable.8')}
                            rows={3}
                            value={formikBag.values.criticalLinesOfBusiness}
                            error={formikBag.errors.criticalLinesOfBusiness}
                            handleChange={formikBag.handleChange}
                            setVal={formikBag.setFieldValue}
                        />
                    </div>

                    <div className="summary-section">
                        <TextAreaField
                            id="space"
                            label={translate('CermAssessmentSummary.Lable.10')}
                            placeholder={translate('CermAssessmentSummary.Lable.9')}
                            rows={3}
                            value={formikBag.values.space}
                            error={formikBag.errors.space}
                            handleChange={formikBag.handleChange}
                            setVal={formikBag.setFieldValue}
                        />
                    </div>

                    <div className="summary-section">
                        <TextAreaField
                            id="staff"
                            label={translate('CermAssessmentSummary.Lable.11')}
                            placeholder={translate('CermAssessmentSummary.Lable.12')}
                            rows={10}
                            value={formikBag.values.staff}
                            error={formikBag.errors.staff}
                            handleChange={formikBag.handleChange}
                            setVal={formikBag.setFieldValue}
                        />
                    </div>
                    <div className="summary-section">
                        <TextAreaField
                            id="contactInfo"
                            label={translate('CermAssessmentSummary.Lable.13')}
                            placeholder={translate('CermAssessmentSummary.Lable.14')}
                            rows={3}
                            value={formikBag.values.contactInfo}
                            error={formikBag.errors.contactInfo}
                            handleChange={formikBag.handleChange}
                            setVal={formikBag.setFieldValue}
                        />
                    </div>
                </div>

                <div className="cerm-assessment-summary__overview">
                    <ReadOnlyField
                        label={translate('CermAssessmentSummary.Label.Score')}
                        value={snapshot && snapshot.isExpertReview ? GetPercentage(snapshot.score) : "0%"}
                    />

                    <div className="columns">
                        <div className="column">
                            <ReadOnlyField
                                label={translate(
                                    'CermAssessmentSummary.Label.AssessmentPerformedBy'
                                )}
                                value={snapshot && snapshot.isExpertReview ? snapshot.expertReviewStatus?.startedByName ?? snapshot.reviewBy : "N/A"}
                            />
                        </div>

                        <div className="column">
                            <ReadOnlyField
                                label={translate('CermAssessmentSummary.Label.Date')}
                                value={snapshot && snapshot.isExpertReview ? snapshot.date.toDateString() : "N/A"}
                            />
                        </div>

                        <div className="column">
                            <ReadOnlyField
                                label={translate('CermAssessmentSummary.Label.ReviewType')}
                                value={snapshot && snapshot.isExpertReview ? snapshot.reviewType : "N/A"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
