import classNames from 'classnames';
import * as React from 'react';
import './Nav.scss';

export const NavBar: React.FC<React.AllHTMLAttributes<HTMLDivElement>> = (props) => {
    const css = classNames([
        'navbar',
        props.className
    ]);

    return (
        <nav className={css} role="navigation" aria-label="Navigation">
            <div className="container">
                {props.children}
            </div>
        </nav>
    );
};

export const EnvironmentSign: React.FC<React.AllHTMLAttributes<HTMLDivElement>> = () => {
    const lowerCaseUrl = window.location.host.toLowerCase();

    const urlIncludes = (input: string) => {
        return lowerCaseUrl.includes(input.toLowerCase());
    };

    const urlIs = (input: string) => {
        return lowerCaseUrl === input.toLowerCase();
    };

    const isLocal = urlIncludes('localhost');
    const isDev = urlIs('dcsquantum.azurewebsites.net');
    const isQat = urlIncludes('qat-dcsquantum');
    const isUat = urlIncludes('uat-quantum');

    return (
        <div className="navbar-home-environment">
            {isLocal && <span className="local">LOCAL</span>}
            {isDev && <span className="dev">DEV</span>}
            {isQat && <span className="qat">QAT</span>}
            {isUat && <span className="uat">UAT</span>}
        </div>
    );
};
