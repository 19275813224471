import * as React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import useTranslate from 'translations/translation-utils';
Chart.register(...registerables);

export interface IStackedLineChartProps {
    data: any;
}

export const StackedLineChart: React.FC<IStackedLineChartProps> = (props) => {
    const translate = useTranslate();
    const chartOptions: any = {
        plugins: {
            legend: {
                display: false,
            },
        },
        elements: {
            point: { radius: 0 },
            line: { tension: 0 },
        },
        tooltips: {
            callbacks: {
                title: (tooltipItem) => {
                    let labels = tooltipItem[0].label.split(',')
                    labels.shift();
                    let label = labels.join(' ') || '';
                    if (label) {
                        label += ': ';
                    }
                    return label;
                }
            }
        },
        maintainAspectRatio: true,
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    display: true,
                    stepSize: 1,
                },
                title: {
                    display: false,
                    text: ''
                },
                stacked: true,
            },
            x: {
                pointlabel: (value, index, values) => {
                    if (values.length > 10 && index >= 0) {
                        return value[0];
                    }
                    return value
                },
                title: {
                    text: translate('Engineer.Skills.Label.5'),
                    display: true
                }
            },
        },
    };

    return (
        <Line
            data={props.data}
            width={500}
            height={180}
            options={chartOptions}
        />
    );
};
