import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import DetailsTab from './tabs/details/incident-details';
import ApprovalsTab from './tabs/approvals/incident-approvals';
import NotificationsTab from './tabs/notifications/incident-notifications';
import RiskActionsTab from './tabs/risk-actions/incident-risk-actions';
import { FormikProps, Formik } from 'formik';
import { IIncident, IApproval, IIncidentLessonsLearned } from 'models/incident';
import { object, string, mixed, array, date, boolean } from 'yup';
import actions from './actions';
import selectors from './selectors';
import { Loader } from 'components/loader';
import {
    IncidentStatus,
    IncidentApprovalStatus,
    ImpactedSystem,
    IncidentImpact,
    ScaleOfImpact,
    ContributingFactors,
} from 'models';
import { IncidentRootCause, IncidentSeverity, IncidentType } from 'models/incident-enums';
import { IPersonLookup } from 'models/person-lookup';
import { IIncidentSettings, IMandatoryClaimCollections } from 'models/incidents/incident-settings';
import { getMandatoryPeopleInfo } from 'business-area-functions/incidents';
import { HistoryTab } from './tabs/history';
import { getUserId } from 'helpers/helpers';
import { IncidentApprovalBarContainer } from 'components/incident-approval-bar/incident-approval-bar-container';
import DocumentsTab from './tabs/documents/incident-documents';
import { ISiteSpecificClaim } from '../../../models/incidents/incident-settings';
import { ConfirmWithCommentDialog } from 'components/confirmationDialog/ConfirmWithCommentDialog';
import { Tab } from 'models/incidents/incident-tabs';
import Page from 'components/v2/page/Page';
import {
    Button,
    ButtonWithConfirmDialog,
    ConfirmDialog,
    TabGroup,
    TabItem,
} from 'components/v2/components';
import { isEmpty } from 'lodash';
import { history } from 'routes/App';
import moment from 'moment';
import useTranslate from 'translations/translation-utils';
import { IncidentCircleStatus } from 'components/circle-data-displayer/incident-circle-status';
import { IncidentArrow } from 'components/circle-data-displayer/incident-arrow';
import { LessonsLearnedDialog } from './lessons-learned-dialog';

interface IProps extends RouteComponentProps<IParams> {
    isSaving: boolean;
    incident: IIncident;
    currentUserId: string;
    isLoading: boolean;
    users: IPersonLookup[];
    incidentSettings: IIncidentSettings;
    siteId: string;
    canArchive: boolean;
    canCreate: boolean;
    canUpdate: boolean;
    loadIncident: (id: string, siteId: string) => void;
    loadIncidentSettings: () => void;
    createIncident: (values: Partial<IIncident>, shouldRedirect: boolean) => void;
    updateIncident: (values: Partial<IIncident>, shouldRedirect: boolean) => void;
    deleteIncident: (id: string, siteId: string) => void;
    onSendEmails(id: string, applicationUsers: string[], emails: string[]);
    cleanUpIncident: () => void;
    loadPersonsLookup: (siteId: string) => void;
    loadPersonProfile: (id: string) => void;
}

interface IParams {
    id?: string;
    tab: string;
    siteId?: string;
}

const UpsertIncident: React.FC<IProps> = (props) => {
    let formikBag: FormikProps<Partial<IIncident>>;
    const translate = useTranslate();
    const validationSchema = object<Partial<IIncident>>().shape({
        title: string().trim().required(translate('Validations.Title')),
        siteId: string().required(translate('Validations.Site')),
        type: mixed()
            .when(['severity'], (severity) => {
                const result = formikBag.values.type && (formikBag.values.type?.toString() == IncidentType.FullIncident || (formikBag.values.type?.toString() == IncidentType.NearMiss && severity == IncidentSeverity.S4))
                const msg = formikBag.values.type ? translate('Validations.NearMiss') : translate('Validations.Type')
                return string().nullable(true).test('typeTest',
                    msg,
                    () => {
                        return result;
                    }
                )
            }),
        severity: mixed().required(translate('Validations.Severity'))
            .when('rootCause', {
                is: (value: IncidentRootCause) => value === IncidentRootCause.EquipmentFailure,
                then: string().required(translate('Validations.Equipment')).nullable(true),
            }),
        majorIncident: string().nullable(true).required(translate('Validations.MajorIncident')),
        contributingFactors: array(),
        impactedSystems: array(),
        rootCause: string()
            .nullable(true)
            .required(translate('IncidentDetails.Label.PrimaryRootCause') + ' is required'),
        incidentImpact: array(),
        isBusinessImpacted: boolean().required('Is business impacted?'),
        businessImpactDescription: string()
            .notRequired()
            .nullable(true)
            .when('isBusinessImpacted', {
                is: (value: boolean) => value === true,
                then: string().required(translate('Validations.BusinessImpact')).nullable(true),
            }),
        hasLessonsLearned: boolean().required(translate('Validations.LessionsLearned')),
        lessonLearnDescription: string()
            .notRequired()
            .nullable(true)
            .when('hasLessonsLearned', {
                is: (value: boolean) => value === true,
                then: string().required(translate('Validations.LessionsLearnedDes')).nullable(true),
            }),
        lessonsLearned: object<Partial<IIncidentLessonsLearned>>()
            .notRequired()
            .nullable(true)
            .shape({
                title: string()
                    .notRequired()
                    .nullable(true)
                    .when('isCreated', {
                        is: (value: boolean) => value === true,
                        then: string()
                            .required(translate('Validations.LessionsLearnedTitle'))
                            .nullable(true),
                    }),
            }),
        startDateTime: date().nullable(true).required(translate('Validations.OpenDate')),
        localDateTime: string().nullable(true).required(translate('Validations.OpenDate')),
        resolvedDateTime: date().nullable(true),
        resolvedLocalDateTime: string().nullable(true).when(
            ['startDateTime', 'localDateTime', 'resolvedDateTime'],
            (startDateTime?: Date, localDateTime?: string, resolvedDateTime?: Date) => {
                const resolvedLocalDateTime = formikBag.values.resolvedLocalDateTime;
                const startDate = localDateTime != null
                    ? moment(localDateTime, 'DD-MM-YYYY HH:mm:ss')
                    : moment(startDateTime);
                const resolvedDate = resolvedLocalDateTime != null
                    ? moment(resolvedLocalDateTime, 'DD-MM-YYYY HH:mm:ss')
                    : moment(resolvedDateTime);

                const result = !resolvedDate.isValid() || resolvedDate.isAfter(startDate);
                return string().nullable(true).test('resolvedDateTest',
                    translate('Validations.OpenDate.2'),
                    () => {
                        return result;
                    }
                )
            }
        ),
        finishDateTime: date().nullable(true),
        finishLocalDateTime: string().nullable(true).when(
            ['resolvedDateTime', 'resolvedLocalDateTime', 'finishDateTime'],
            (resolvedDateTime?: Date, resolvedLocalDateTime?: string, finishDateTime?: Date) => {
                let finishLocalDateTime = formikBag.values.finishLocalDateTime
                const resolvedDate = resolvedLocalDateTime != null
                    ? moment(resolvedLocalDateTime, 'DD-MM-YYYY HH:mm:ss')
                    : moment(resolvedDateTime);
                const finishedDate = finishLocalDateTime != null
                    ? moment(finishLocalDateTime, 'DD-MM-YYYY HH:mm:ss')
                    : moment(finishDateTime);

                const result = !resolvedDate.isValid() || !finishedDate.isValid() || finishedDate.isAfter(resolvedDate);
                return string().nullable(true).test('finishDateTest',
                    translate('Validations.ReslovedDate'),
                    () => {
                        return result
                    }
                )
            }
        ),
        responsiblePeople: array<string>().min(1, translate('Validations.IncidentOwner')),
        other: string()
            .when('contributingFactors', {
                is: (value: ContributingFactors[]) =>
                    value.some((s) => s === ContributingFactors.Other),
                then: string().required(translate('Validations.Gen.1')).nullable(true),
            })
            .when('contributingFactors', {
                is: (value: ContributingFactors[]) =>
                    (value.length === 0),
                then: string().required(translate('Validations.Gen.2')).nullable(true),
            })
            .nullable(true),
        impactedSystemOther: string()
            .when('impactedSystems', {
                is: (value: ImpactedSystem[]) =>
                    value.some((s) => s === ImpactedSystem.ImpactedSystemOther),
                then: string().required(translate('Validations.Gen.1')).nullable(true),
            })
            .when('impactedSystems', {
                is: (value: ImpactedSystem[]) =>
                    (value.length === 0),
                then: string().required(translate('Validations.Gen.2')).nullable(true),
            })
            .nullable(true),
        incidentImpactOther: string()
            .when('incidentImpact', {
                is: (value: IncidentImpact[]) =>
                    value.some((s) => s === IncidentImpact.IncidentImpactOther),
                then: string().required(translate('Validations.Gen.1')).nullable(true),
            })
            .when('incidentImpact', {
                is: (value: IncidentImpact[]) =>
                    (value.length === 0),
                then: string().required(translate('Validations.Gen.2')).nullable(true),
            })
            .nullable(true),
        scaleOfImpactOther: string()
            .when('scaleOfImpact', {
                is: (value: ScaleOfImpact[]) =>
                    value.some((s) => s === ScaleOfImpact.ScaleOfImpactOther),
                then: string().required(translate('Validations.Gen.1')).nullable(true),
            })
            .when('scaleOfImpact', {
                is: (value: ScaleOfImpact[]) =>
                    (value.length === 0),
                then: string().required(translate('Validations.Gen.2')).nullable(true),
            })
            .nullable(true),
        equipmentName: string()
            .when('rootCause', {
                is: (value: IncidentRootCause) => isEquipmentFailure(value),
                then: string().required(translate('Validations.Equipment')).nullable(true),
            })
            .nullable(true),
        equipmentModel: string()
            .when('rootCause', {
                is: (value: IncidentRootCause) => isEquipmentFailure(value),
                then: string().required(translate('Validations.EquipmentModel')).nullable(true),
            })
            .nullable(true),
        equipmentManufacturer: string()
            .when('rootCause', {
                is: (value: IncidentRootCause) => isEquipmentFailure(value),
                then: string().required(translate('Validations.EquipmentManufacturer')).nullable(true),
            })
            .nullable(true),
    });

    const incidentsPageUrl = '/Incidents';
    const [isValid, setIsValid] = React.useState(false);
    const [isValidDetails, setIsValidDetails] = React.useState(props.match.params.id ? true : false);
    const [showReopenPopup, setShowReopenPopup] = React.useState(false);
    const [shouldRedirect, setShouldRedirect] = React.useState(true);
    const [isTimeValid, setIsTimeValid] = React.useState(false);
    const [notifiedPeople, setNotifiedPeople] = React.useState<string[]>([]);
    const [showTabDisabledDialog, setShowTabDisabledDialog] = React.useState(false);
    const [showApproversListDialog, setShowApproversListDialog] = React.useState(false);
    const [showLessonsLearnedDialog, setShowLessonsLearnedDialog] = React.useState(false);
    const currentTab = props.match.params.tab.toLowerCase();
    const currentSiteId = props.match.params.siteId ? props.match.params.siteId : props.siteId
    const [majorIncidentUpdated, setMajorIncidentUpdated] = React.useState(false)
    const [businessImpactUpdated, setBusinessImpactUpdated] = React.useState(false)
    const [lessonLearnUpdated, setLessonLearnUpdated] = React.useState(false)
    const [hasLessonsLearned, setHasLessonsLearned] = React.useState(false);

    const updateValidDetails = (value: boolean) => {
        if (isValidDetails != value) {
            setIsValidDetails(value);
        }
    };

    React.useEffect(() => {
        if (props.match.params.id && currentSiteId) {
            props.loadIncident(props.match.params.id, currentSiteId);
            setHasLessonsLearned(props.incident.hasLessonsLearned);
        }
    }, [props.match.params.siteId, props.match.params.id]);

    React.useEffect(() => {
        if (props.incident) {
            setHasLessonsLearned(props.incident.hasLessonsLearned);
        }
    }, [props.incident]);

    React.useEffect(() => {
        props.cleanUpIncident();
        props.loadPersonsLookup(props.siteId);
        props.loadIncidentSettings();
    }, []);

    React.useEffect(() => {
        if (!getUserId()) {
            return;
        }
        if (props.match.params.siteId) {
            props.loadPersonProfile(getUserId());
        }
    }, [props.siteId]);

    const setPeopleNotified = (people) => {
        setNotifiedPeople(people)
    };

    const setTimeValid = (isValid: boolean) => {
        setIsTimeValid(isValid)
    };

    const getIncidentId = (): string | null | undefined => {
        return props.match.params.id
    };

    const getUpdatedDateTime = (dateTime?: Date, localDateTime?: string): Date => {
        const dt = localDateTime != null
            ? moment(localDateTime, 'DD-MM-YYYY HH:mm:ss')
            : moment(dateTime);

        return moment(dateTime)
            .day(dt.day())
            .month(dt.month())
            .year(dt.year())
            .hour(dt.hour())
            .minute(dt.minute())
            .toDate();
    };

    const onSubmitIncident = async (status?: IncidentStatus) => {
        if (status) {
            await formikBag.setFieldValue('status', status);
        }

        if (formikBag.values.localDateTime != moment(formikBag.values.startDateTime).format('DD-MM-YYYY HH:mm:ss')) {
            await formikBag.setFieldValue(
                'startDateTime',
                getUpdatedDateTime(
                    formikBag.values.startDateTime,
                    formikBag.values.localDateTime
                )
            );
        }

        if (formikBag.values.finishDateTime) {
            if (formikBag.values.finishLocalDateTime != moment(formikBag.values.finishDateTime).format('DD-MM-YYYY HH:mm:ss')) {
                await formikBag.setFieldValue(
                    'finishDateTime',
                    getUpdatedDateTime(
                        formikBag.values.finishDateTime,
                        formikBag.values.finishLocalDateTime
                    )
                );
            }
        }

        if (majorIncidentUpdated == false) {
            setMajorIncidentUpdated(true)
        }

        if (businessImpactUpdated == false) {
            setBusinessImpactUpdated(true)
        }

        if (lessonLearnUpdated == false) {
            setLessonLearnUpdated(true)
        }

        formikBag.submitForm();
    };

    const onSubmitApproval = async () => {
        onSubmitIncident(IncidentStatus.Pending);
    };

    const onMainCloseButtonClick = () => {
        if (hasLessonsLearned) {
            setShowLessonsLearnedDialog(true);
        } else {
            setShowApproversListDialog(true);
        }
    };

    const onConfirmLessonsLearnedDialog = () => {
        formikBag.validateForm().then(() => {
            if (isValid) {
                setShowLessonsLearnedDialog(false);
                setShowApproversListDialog(true);
            }
        });
    };

    const onLessonsLearnedChange = (value: boolean) => {
        setHasLessonsLearned(value);
    };

    const onCloseIncident = async () => {
        overrideApprovalResponses();
        onSubmitIncident(IncidentStatus.Closed);
    };

    const onReopen = () => {
        setShowReopenPopup(true)
    };

    const cancelReopenPopup = () => {
        setShowReopenPopup(false);
    };

    const onReopenIncident = (comment: string) => {
        let reopenComments: string[] = !isEmpty(formikBag.values.reopenComments)
            ? formikBag.values.reopenComments
            : [];
        reopenComments = [...reopenComments, comment];
        formikBag.setFieldValue('reopenComments', reopenComments);
        setShowReopenPopup(false)
        onSubmitIncident(IncidentStatus.Open);
    };

    const onCreate = async () => {
        onSubmitIncident(IncidentStatus.Open);
    };

    const onArchive = () => {
        if (formikBag.values.id) {
            props.deleteIncident(formikBag.values.id, formikBag.values.siteId);
        }
    };

    const onSave = () => {
        onSubmitIncident();
    };

    const onSubmit = (values: Partial<IIncident>) => {
        if (values.id) {
            props.updateIncident(values, shouldRedirect);
        } else {
            props.createIncident(values, shouldRedirect);
        }
        setShouldRedirect(true)
    };

    const overrideApprovalResponses = () => {
        const usedIds = getMandatoryUsers(
            props.incidentSettings.mandatoryApprovals,
            formikBag.values.approvers
        );
        const approvalResponses = formikBag.values.approvalResponses;
        let newApprovalResponses: IApproval[] = approvalResponses ? approvalResponses.slice() : [];
        if (usedIds.length > 0) {
            usedIds.map(async (id) => {
                const response = getApprovalResponse(approvalResponses, id);
                if (response !== IncidentApprovalStatus.Approved) {
                    const user = props.users.find((u) => u.id === props.currentUserId);
                    const approval = {
                        approver: id,
                        statusDate: new Date(),
                        status: IncidentApprovalStatus.Overridden,
                        comment: `Overridden by ${user.label}`,
                    } as IApproval;
                    newApprovalResponses = [...newApprovalResponses, approval];
                }
            });
        }
        formikBag.setFieldValue('approvalResponses', newApprovalResponses);
    };

    const getNoSiteRolesMessage = () => {
        return (
            <div>
                {translate('IncidentPage.Message.NoSiteRoles1')}
                <a href={`/Site/Upsert/${props.incident.siteId}/Form/Users`} target="_blank" rel="noopener noreferrer" >
                    {' '}
                    {translate('IncidentPage.Message.NoSiteRoles2')}{' '}
                </a>{' '}
                {translate('IncidentPage.Message.NoSiteRoles3')}
            </div>
        );
    };

    const getNotificationModalBody = () => {
        const usedIds = getMandatoryUsers(
            props.incidentSettings.mandatoryNotifications,
            notifiedPeople
                .concat(formikBag?.values?.responsiblePeople ?? props.incident.responsiblePeople)
                .concat(formikBag?.values?.notifiedPeople ?? props.incident.notifiedPeople)
        );
        if (usedIds.length > 0 && props.users && props.users.length > 0) {
            return (
                <React.Fragment>
                    <b>{translate('IncidentPage.Message.ModalBody')}</b>
                    <ul>
                        {usedIds.map((n) => {
                            const user = props.users.find((u) => u.id === n);
                            if (user) {
                                return <li key={user.id}> {user.label} </li>;
                            }
                        })}
                    </ul>
                </React.Fragment>
            );
        } else {
            return getNoSiteRolesMessage();
        }
    };

    const getApprovalModalBody = () => {
        const usedIds = getMandatoryUsers(
            props.incidentSettings.mandatoryApprovals,
            notifiedPeople
        );
        if (usedIds.length > 0) {
            return (
                <React.Fragment>
                    <b>{translate('IncidentPage.Message.ApprovalModalBody')}</b>
                    <ul>
                        {usedIds.map((n) => {
                            const user = props.users.find((u) => u.id === n);
                            if (user) {
                                return <li key={user.id}> {user.label} </li>;
                            }
                        })}
                    </ul>
                </React.Fragment>
            );
        } else {
            return getNoSiteRolesMessage();
        }
    };

    const getCloseModalBody = () => {
        const usedIds = getMandatoryUsers(
            props.incidentSettings.mandatoryApprovals,
            notifiedPeople
        );
        if (usedIds.length > 0) {
            return (
                <>
                    <b>{translate('IncidentPage.confirmMessage.CloseModalBodyOverride')}</b>
                    <ul>
                        {usedIds.map((n) => {
                            const user = props.users.find((u) => u.id === n);
                            if (user) {
                                return (
                                    <li key={user.id}>
                                        {' '}
                                        {user.label} :{' '}
                                        {getApprovalResponse(
                                            props.incident.approvalResponses,
                                            user.id
                                        )}
                                    </li>
                                );
                            }
                        })}
                    </ul>
                </>
            );
        } else {
            return <p>{translate('IncidentPage.confirmMessage.CloseModalBody')}</p>;
        }
    };

    const onCancel = () => {
        formikBag.resetForm();
    };
    const getApprovalResponse = (approvalResponses: IApproval[], userId: string) => {
        if (approvalResponses.length > 0 && approvalResponses.some((s) => s.approver === userId)) {
            const answers = approvalResponses
                .filter((a) => a.approver === userId)
                .sort(
                    (a, b) => new Date(b.statusDate).getTime() - new Date(a.statusDate).getTime()
                );
            return translate('Globals.Status.', answers[0].status);
        }
        return translate('Globals.Status.Unanswered');
    }
    const getMandatoryUsers = (
        mandatoryCollection: IMandatoryClaimCollections,
        additionalUsers: string[]
    ) => {
        const mandatoryUsers = getMandatoryPeopleInfo(
            props.incident,
            props.users,
            mandatoryCollection
        );
        const usedIds = mandatoryUsers && mandatoryUsers
            .map((m) => m.id)
            .concat(additionalUsers)
            .filter((v, i, a) => a.indexOf(v) === i);
        return usedIds;
    }
    const renderTab = (formik: FormikProps<Partial<IIncident>>) => {
        formikBag = formik;
        setIsValid(formik.isValid && (!formikBag.errors || Object.keys(formikBag.errors).length === 0));
        let tabContent = null;

        switch (currentTab) {
            case Tab.details:
                tabContent = (
                    <DetailsTab
                        incidentId={getIncidentId()}
                        formikBag={formikBag}
                        majorIncidentUpdated={majorIncidentUpdated}
                        setMajorIncidentUpdated={setMajorIncidentUpdated}
                        setBusinessImpactUpdated={setBusinessImpactUpdated}
                        onLessonsLearnedChange={onLessonsLearnedChange}
                        setIsValidDetails={updateValidDetails}
                        setTimeValid={setTimeValid}
                    />
                );
                break;
            case Tab.riskActions:
                tabContent = <RiskActionsTab formikBag={formikBag} />;
                break;
            case Tab.documents:
                tabContent = <DocumentsTab id={formikBag.values.id} />;
                break;
            case Tab.notifications:
                tabContent = (
                    <NotificationsTab
                        formikBag={formikBag}
                        mandatoryNotifications={props.incidentSettings.mandatoryNotifications}
                        setNotifiedPeople={setPeopleNotified}
                    />
                );
                break;
            case Tab.approvals:
                tabContent = (
                    <ApprovalsTab
                        formikBag={formikBag}
                        mandatorySettingApprovals={props.incidentSettings.mandatoryApprovals}
                    />
                );
                break;
            case Tab.history:
                tabContent = <HistoryTab form={formikBag} />;
                break;
        }

        return (
            <>
                {tabContent}
                <LessonsLearnedDialog
                    formikBag={formikBag}
                    isVisible={showLessonsLearnedDialog}
                    onConfirm={onConfirmLessonsLearnedDialog}
                    onClose={() => setShowLessonsLearnedDialog(false)}
                />
            </>
        );
    };

    const getCreateButtons = () => {
        if (!props.canCreate) {
            return <></>
        }

        const tab = props.match.params.tab;
        if (
            props.incident &&
            props.incident.status === IncidentStatus.Draft
        ) {
            return (
                <>
                    <ButtonWithConfirmDialog
                        title={translate(

                            'IncidentPage.confirmMessage.ConfirmIncidentCreation'
                        )}
                        message={getNotificationModalBody()}
                        onConfirm={onCreate}
                        buttonId="finish-button"
                        buttonText={translate('IncidentPage.Button.Create')}
                        buttonClassName="is-pulled-right"
                        isButtonDisabled={
                            props.isLoading ||
                            props.isSaving ||
                            !isValid
                        }
                    />
                    <Button
                        className="is-pulled-right"
                        onClick={onSave}
                        disabled={
                            props.isLoading ||
                            props.isSaving ||
                            !isValid ||
                            !isValidDetails ||
                            (!isTimeValid && tab === 'details')
                        }
                    >
                        {translate('IncidentPage.Button.SaveDraft')}
                    </Button>
                </>
            );
        }
    }

    const getUpdateButtons = () => {
        if (!props.canUpdate) {
            return <></>;
        }

        const tab = props.match.params.tab;
        const siteClaims: ISiteSpecificClaim =
            props.incident &&
                props.incident.severity &&
                props.incidentSettings &&
                props.incidentSettings.mandatoryApprovals.siteSpecificClaims
                ? props.incidentSettings.mandatoryApprovals.siteSpecificClaims.find(
                    (x) => x.incidentSeverity === props.incident.severity)
                : { incidentSeverity: '', siteClaim: [], hasLessonsLearned: null };

        if (props.incident && props.incident.status === IncidentStatus.Draft) {
            return (
                <>
                    <ButtonWithConfirmDialog
                        title={translate('IncidentPage.confirmMessage.ConfirmIncidentCreation')}
                        message={getNotificationModalBody()}
                        onConfirm={onCreate}
                        buttonId="finish-button"
                        buttonText={translate('IncidentPage.Button.Create')}
                        buttonClassName="is-pulled-right"
                        isButtonDisabled={
                            props.isLoading ||
                            props.isSaving ||
                            !isValid ||
                            !isValidDetails ||
                            (!isTimeValid && tab === 'details')
                        }
                    />
                    <Button
                        className="is-pulled-right"
                        onClick={onSave}
                        disabled={
                            props.isLoading ||
                            props.isSaving ||
                            !isValid ||
                            !isValidDetails ||
                            (!isTimeValid && tab === 'details')
                        }
                    >
                        {translate('IncidentPage.Button.SaveDraft')}
                    </Button>
                </>
            );
        }

        if (props.incident &&
            props.incident.status === IncidentStatus.Open &&
            ((
                siteClaims &&
                (
                    (siteClaims.siteClaim.length > 0 && siteClaims.hasLessonsLearned === null) ||
                    (siteClaims.siteClaim.length === 0 &&
                        siteClaims.hasLessonsLearned === true &&
                        hasLessonsLearned)
                )
            ) || (props.incident && props.incident.approvers.length > 0))
        ) {
            return (
                <>
                    <ButtonWithConfirmDialog
                        title={translate('IncidentPage.confirmMessage.Title')}
                        message={getApprovalModalBody()}
                        onConfirm={onSubmitApproval}
                        buttonId="finish-button"
                        buttonText={translate('IncidentPage.Button.SubmitforApproval')}
                        buttonClassName="is-pulled-right"
                        isButtonDisabled={
                            props.isLoading ||
                            props.isSaving ||
                            !isValid ||
                            !isValidDetails ||
                            (!isTimeValid && tab === 'details')
                        }
                    />
                    <Button
                        className="is-pulled-right"
                        onClick={onSave}
                        disabled={
                            props.isLoading ||
                            props.isSaving ||
                            !isValid ||
                            !isValidDetails ||
                            (!isTimeValid && tab === 'details')
                        }
                    >
                        {translate('IncidentPage.Button.Save')}
                    </Button>
                </>
            );
        }

        if (
            (
                props.incident &&
                props.incident.status === IncidentStatus.Pending) ||
            (
                props.incident &&
                props.incident.status === IncidentStatus.Open &&
                siteClaims &&
                siteClaims.siteClaim.length === 0 &&
                props.incident &&
                props.incident.approvers.length === 0)
        ) {
            return (
                <>
                    <Button
                        className="is-pulled-right"
                        onClick={onSave}
                        disabled={

                            props.isLoading ||
                            props.isSaving ||
                            !isValid ||
                            !isValidDetails ||
                            (!isTimeValid && tab === 'details')
                        }
                    >
                        {translate('IncidentPage.Button.Save')}
                    </Button>

                    <Button
                        title={translate('IncidentDetails.Label.ConfirmIncidentClosed')}
                        className="is-pulled-right"
                        disabled={
                            props.isLoading ||
                            props.isSaving ||
                            props.incident.resolvedLocalDateTime === null ||
                            props.incident.finishLocalDateTime === null ||
                            !isValid ||
                            !isValidDetails ||
                            (!isTimeValid && tab === 'details')
                        }
                        onClick={onMainCloseButtonClick}
                    >
                        {translate('IncidentPage.Button.CloseIncident')}
                    </Button>
                </>
            );
        }
        if (
            props.incident &&
            props.incident.status === IncidentStatus.Closed
        ) {
            return (
                <>
                    <Button
                        className="is-pulled-right"
                        onClick={onReopen}
                        disabled={props.isSaving}
                    >
                        {translate('IncidentPage.Button.ReopenIncident')}
                    </Button>
                    {showReopenPopup && (
                        <ConfirmWithCommentDialog
                            confirmMessage={translate(

                                'IncidentPage.confirmMessage.showReopenPopup'
                            )}
                            onConfirm={onReopenIncident}
                            onClose={cancelReopenPopup}
                            requireComment={true}
                        />
                    )}
                </>
            );
        }
    };
    const isIncidentIdAvailable = () => {
        return isEmpty(props.match.params.id) ? false : true;
    };
    const setNavLink = (selectedTab: Tab) => {
        if (props.match.params.id) {
            return props.match.path
                .replace(':id?', props.match.params.id)
                .replace(':tab', selectedTab)
                .replace(':siteId?', '');
        }

        return props.match.path.replace(':id?/', '').replace(':tab', selectedTab).replace(':siteId?', '');
    };
    const handleClick = (event: any, tab: Tab) => {
        event.preventDefault();
        if (!isIncidentIdAvailable()) {
            setShowTabDisabledDialog(true);
            return;
        }

        if (formikBag && formikBag.values?.status === "Closed") {
            history.push(setNavLink(tab));
            return;
        }

        formikBag && formikBag.validateForm().then((errors) => {
            if (!Object.keys(errors).some((field) => field.length > 0)) {
                history.push(setNavLink(tab));
            }
        });
    };
    const mandatoryUsers =
        props.users && props.incidentSettings
            ? getMandatoryPeopleInfo(
                props.incident,
                props.users,
                props.incidentSettings.mandatoryApprovals
            )
            : [];
    const approvers: string[] = mandatoryUsers
        .map((x) => x.id)
        .concat(props.incident.approvers);
    const incidentUid = !!props.incident.uid ? `(${props.incident.uid}) - ` : ``;
    const title = props.match.params.id
        ? props.incident && props.incident.title
        : translate('IncidentPage.Title.NewIncident');
    const showStatusBar =
        props.incident.status !== IncidentStatus.Draft &&
        !(
            !!!props.incident.resolvedLocalDateTime &&
            props.incident.status === IncidentStatus.Closed
        );
    const showStatusBarResolvedSection = !(
        !!!props.incident.resolvedLocalDateTime && !!props.incident.finishLocalDateTime
    );

    const isEquipmentFailure = (value) => {
        const translatedValue = translate('IncidentDetails.incidentRootCause.EquipmentFailure');
        if (value === null || translatedValue === null) {
            return false;
        }
        const processedValue = translatedValue.replace(/[^\w]/g, '');
        return processedValue === value.replace(/[^\w]/g, '');
    }

    return (
        <Page
            title={`${incidentUid}${title}`}
            breadcrumbCustomLinks={[
                {
                    key: incidentsPageUrl,
                    value: translate('IncidentPage.Title.Incidents'),
                },
            ]}
            redirectOnSiteChange={props.match.params.siteId ? false : true}
            redirectOnSiteChangeUrl={incidentsPageUrl}
            scrollTopOnPageLoad={true}
        >
            {props.incident &&
                approvers.some((x) => x === getUserId()) &&
                props.incident.status === IncidentStatus.Pending && (
                    <IncidentApprovalBarContainer incident={props.incident} />
                )}

            {showStatusBar && (
                <>
                    <div className="horizontal-layout width-60">
                        <IncidentCircleStatus
                            text={translate('IncidentRiskActions.Label.Open')}
                            active={!!props.incident.localDateTime}
                        />
                        {showStatusBarResolvedSection && (
                            <IncidentArrow
                                startDate={props.incident.localDateTime}
                                endDate={props.incident.resolvedLocalDateTime}
                                text={translate('IncidentRiskActions.Label.SLADuration')}
                                active={!!props.incident.resolvedDateTime}
                            />
                        )}
                        {showStatusBarResolvedSection && (
                            <IncidentCircleStatus
                                text={translate('IncidentRiskActions.Label.Resolved')}
                                active={!!props.incident.resolvedLocalDateTime}
                            />
                        )}
                        <IncidentArrow
                            startDate={props.incident.localDateTime}
                            endDate={props.incident.finishLocalDateTime}
                            text={translate('IncidentRiskActions.Label.IncidentDuration')}
                            active={!!props.incident.finishLocalDateTime}
                        />
                        <IncidentCircleStatus
                            text={translate('IncidentRiskActions.Label.Closed')}
                            active={!!props.incident.finishLocalDateTime}
                        />
                    </div>
                    <br />
                </>
            )}

            <TabGroup>
                <TabItem
                    title={translate('IncidentPage.Tab.Details')}
                    tab={Tab.details}
                    currentTab={currentTab}
                    onClick={handleClick}
                />
                <TabItem
                    title={translate('IncidentPage.Tab.RiskNActions')}
                    tab={Tab.riskActions}
                    currentTab={currentTab}
                    onClick={handleClick}
                />
                <TabItem
                    title={translate('IncidentPage.Tab.Documents')}
                    tab={Tab.documents}
                    currentTab={currentTab}
                    onClick={handleClick}
                />
                <TabItem
                    title={translate('IncidentPage.Tab.Notifications')}
                    tab={Tab.notifications}
                    currentTab={currentTab}
                    onClick={handleClick}
                />
                <TabItem
                    title={translate('IncidentPage.Tab.Approvals')}
                    tab={Tab.approvals}
                    currentTab={currentTab}
                    onClick={handleClick}
                />
                <TabItem
                    title={translate('IncidentPage.Tab.History')}
                    tab={Tab.history}
                    currentTab={currentTab}
                    onClick={handleClick}
                />
            </TabGroup>

            <div className="tabs-container">
                <Formik<Partial<IIncident>>
                    initialValues={props.incident}
                    isInitialValid={props.match.params.id === undefined ? false : true}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                    render={renderTab}
                />
            </div>

            <ConfirmDialog
                title={translate('RiskRegisterUpsertPage.ConfirmDialog.Title')}
                message={translate('IncidentPage.confirmMessage.SaveIncident')}
                buttonCancelHide={false}
                buttonConfirmText={translate('IncidentDetails.ConfirmDialog.ButtonConfirmText')}
                isVisible={showTabDisabledDialog}
                onConfirm={() => setShowTabDisabledDialog(false)}
                onOutsideDialogClick={() => setShowTabDisabledDialog(false)}
            />

            <ConfirmDialog
                title={translate('IncidentDetails.Label.ConfirmIncidentClosed')}
                message={getCloseModalBody()}
                buttonCancelHide={false}
                buttonConfirmText={translate('IncidentDetails.ConfirmDialog.ButtonConfirmText')}
                isVisible={showApproversListDialog}
                onConfirm={onCloseIncident}
                onClose={() => setShowApproversListDialog(false)}
                onOutsideDialogClick={() => setShowApproversListDialog(false)}
            />

            <Loader loading={props.isSaving}>
                <div className="section">
                    <div className="field">
                        <NavLink
                            className="button button-cancel"
                            to={'/incidents'}
                            onClick={onCancel}
                        >
                            {translate('IncidentPage.Button.Cancel')}
                        </NavLink>
                        {props.canArchive &&
                            props.incident &&
                            props.incident.id && (
                                <ButtonWithConfirmDialog
                                    onConfirm={onArchive}
                                    buttonType={'archive'}
                                    buttonText={translate('IncidentPage.Button.Archive')}
                                    message={translate('IncidentPage.Archive.Message')}
                                    title={translate('IncidentPage.Archive.Title')}
                                />
                            )}
                        {props.incident.id === null && getCreateButtons()}
                        {props.incident.id && getUpdateButtons()}
                    </div>
                </div>
            </Loader>
        </Page>
    );
};

export default withRouter(connect(selectors, actions)(UpsertIncident));
