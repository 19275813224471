import * as React from 'react';
import Page from 'components/v2/page/Page';
import { sumBy, isEmpty } from 'lodash';
import { RouteComponentProps } from 'react-router';
import { IMasterProcessStep } from 'models/master-process-step/master-process-step';
import { Table } from 'components/v2/components';
import { Loader } from 'components/loader';
import { IKeyValue } from 'models';
import { ICermAssessmentSnapshot } from 'models/cerm/cerm-assessment-snapshot';
import { GetScorePercentage } from 'utils/decimal-utils';
import { CermAssessmentSnapshotProcessStepRow } from './shared/CermAssessmentSnapshotProcessStepRow';
import { ISnapshotFilters, ISnapshotExportFilters } from 'models/snapshot-filters';
import { CermAssessmentComparisonToolbar } from './shared/CermAssessmentComparisonToolbar';
import { getSiteOperatingPlatform } from 'models/site-profile';
import { ICommonLookupModel } from 'models/common-lookup-model';
import useTranslate from 'translations/translation-utils';
import { IOperatingPlatform } from 'models/operating-platform-model';
import { getTitlePrefixGeneric } from 'models/cerm/cerm-assessment-result';

interface IParams {
    siteId: string;
    idLeft: string;
    idRight: string;
}

interface IProps extends RouteComponentProps<IParams> {
    isLoading: boolean;
    isExporting: boolean;
    siteId: string;
    pillars: ICommonLookupModel[];
    masterProcessSteps: IMasterProcessStep[];
    snapshots: ICermAssessmentSnapshot[];
    onSiteChange: (siteId: string) => void;
    loadMasterProcessSteps: (operatingPlatform: string) => void;
    loadSnapshotsFullResults: (filters: ISnapshotFilters) => void;
    exportSnapshotsComparison: (filters: ISnapshotExportFilters) => void;
    lookupSites: IKeyValue<string>[];
    allOperatingPlatforms: IOperatingPlatform[];
}

export const CermAssessmentComparisonPage: React.FC<IProps> = ({
    match,
    isLoading,
    isExporting,
    pillars,
    masterProcessSteps,
    snapshots,
    onSiteChange,
    loadMasterProcessSteps,
    loadSnapshotsFullResults,
    exportSnapshotsComparison,
    lookupSites,
    allOperatingPlatforms
}) => {
    const translate = useTranslate();
    const siteId = match.params.siteId;
    const idLeft = match.params.idLeft;
    const idRight = match.params.idRight;
    const cermAssessmentPageUrl = '/CermAssessment/';
    const operatingPlatform = getSiteOperatingPlatform(siteId, lookupSites);
    const titlePrefix = getTitlePrefixGeneric(operatingPlatform,allOperatingPlatforms);
    const customLinks: Array<IKeyValue<string>> = [
        { key: cermAssessmentPageUrl, value: `${titlePrefix} Assessment` },
    ];
    React.useEffect(() => {
        if (match.params.siteId) {
            onSiteChange(match.params.siteId);
        }
    }, [onSiteChange]);

    React.useEffect(() => {
        if (getSiteOperatingPlatform(siteId, lookupSites) !== '') {
            loadMasterProcessSteps(getSiteOperatingPlatform(siteId, lookupSites));
        }
    }, [getSiteOperatingPlatform(siteId, lookupSites)]);

    React.useEffect(() => {
        if (siteId && idLeft && idRight) {
            loadSnapshotsFullResults({
                siteId: match.params.siteId,
                ids: [idLeft, idRight],
            } as ISnapshotFilters);
        }
    }, [loadSnapshotsFullResults]);

    const getAvailablePillars = () => {
       return !pillars || !masterProcessSteps
            ? []
            : pillars.filter((p) => masterProcessSteps.some((s) => s.pillar === p.value) && p.operatingPlatform === operatingPlatform);
    }

    const getPillarScore = (pillar: string, snapshot: ICermAssessmentSnapshot) => {
        const result = 'N/A';
        if (!snapshot || isEmpty(snapshot.processSteps)) {
            return result;
        }

        const steps = snapshot.processSteps.filter(
            (step) =>
                step.pillar === pillar &&
                step.applicable === true &&
                masterProcessSteps.some((s) => step.id === s.id)
        );
        const totalSteps = steps.length;
        if (totalSteps < 1) {
            return result;
        }

        const totalCompliances = sumBy(steps, (s) => s.compliance);

        return GetScorePercentage(totalCompliances / totalSteps);
    };

    // const backToPage = () => history.push(cermAssessmentPageUrl);

    const getProcessStep = (snapshotIndex: number, processStepId: string) => {
        if (isEmpty(snapshots) || isEmpty(snapshots[snapshotIndex].processSteps)) {
            return null;
        }

        return snapshots[snapshotIndex].processSteps.find((step) => step.id === processStepId);
    };

    const getRows = (pillar: string, index: number) => {
        const pillarRow = (
            <tr className="pillar">
                <td>{pillar}</td>
                {snapshots.map((snapshot, aKey) => (
                    <td key={aKey}>{getPillarScore(pillar, snapshot)}</td>
                ))}
            </tr>
        );

        const pillarMasterProcessSteps = masterProcessSteps.filter(
            (step) => step.pillar === pillar
        );
        const processStepRows = pillarMasterProcessSteps.map((masterProcessStep, key) => (
            <CermAssessmentSnapshotProcessStepRow
                key={key}
                masterProcessStep={masterProcessStep}
                processStepLeft={getProcessStep(0, masterProcessStep.id)}
                processStepRight={getProcessStep(1, masterProcessStep.id)}
            />
        ));

        return (
            <tbody key={index} className={index % 2 === 0 ? 'alternative' : ''}>
                {pillarRow}
                {processStepRows}
            </tbody>
        );
    };

    const onExport = (includeMeta: boolean) => {
        exportSnapshotsComparison({ siteId, ids: [idLeft, idRight], includeMeta, operatingPlatform: operatingPlatform });
    };



    return (
        <Page
            className="cerm-assessment-comparison-page"
            title={`${translate('CermAssessment.Lable.1')} ${titlePrefix} ${translate('SideNavBar.Labels.Assessment')}`}
            redirectOnSiteChange={true}
            redirectOnSiteChangeUrl={cermAssessmentPageUrl}
            breadcrumbCustomLinks={customLinks}
        >
            <CermAssessmentComparisonToolbar isExporting={isExporting} onExport={onExport} />
            {!isEmpty(masterProcessSteps) && !isEmpty(snapshots) && (
                <Loader loading={isLoading}>
                    <Table>
                        <thead>
                            <tr className="pillar">
                                <th>{titlePrefix} {translate('CermAssessment.Lable.2')}</th>
                                {snapshots.map((result, index) => (
                                    <th key={index}>
                                        {result.dateLabel}{' '}
                                        {result.isExpertReview
                                            ? translate('Cerm.ComparisonPanel.Labels.ExpertReview')  + (result.expertReviewStatus?.startedByName ? ' ' + translate('Cerm.SiteScore.Labels.By') + ' ' + result.expertReviewStatus?.startedByName:'')
                                            : translate( 'Cerm.ComparisonPanel.Labels.SelfAssessment')}
                                        <br />
                                        <strong>Score: {GetScorePercentage(result.score)}</strong>
                                    </th>
                                ))}
                            </tr>
                            <tr className="compliance-label">
                                <th />
                                {snapshots.map((_item, index) => (
                                    <th key={index}>{translate('SideNavBar.Labels.Compliance')} (%)</th>
                                ))}
                            </tr>
                        </thead>
                        {getAvailablePillars().map((pillar, key) => getRows(pillar.value, key))}
                    </Table>
                </Loader>
            )}
        </Page>
    );
};

