// tslint:disable-next-line: max-line-length
import {
    IncidentType,
    IncidentSeverity,
    IncidentRootCause,
    ContributingFactors,
    IncidentStatus,
    ResponsibleParty,
    IncidentApprovalStatus,
    IncidentImpact,
    ImpactedSystem,
    ScaleOfImpact,
} from './incident-enums';
import { IAttachmentCollection } from './attachment-collection';
import { IKeyedItem } from './keyed-item';

export interface IIncidentSummary extends IKeyedItem {
    title: string;
    siteName?: string;
    severity: IncidentSeverity;
    severityData: boolean[][];
    type: IncidentType;
    majorIncident?: boolean;
    majorIncidentData: boolean[][];
    createdDateTime: Date;
    status: IncidentStatus;
    createdByPersonName?: string;
    uid: string;
}

export interface IIncident extends IIncidentSummary {
    description: string;
    responsiblePeople: string[];
    startDateTime: Date;
    resolvedDateTime: Date;
    finishDateTime: Date;
    isBusinessImpacted: boolean;
    businessImpactDescription: string;
    operationalImpactDescription: string;
    hasLessonsLearned: boolean;
    lessonLearnDescription: string;
    lessonsLearned: IIncidentLessonsLearned;
    rootCause?: IncidentRootCause;
    responsibleParty?: ResponsibleParty;
    contributingFactors: ContributingFactors[];
    incidentImpact: IncidentImpact[];
    scaleOfImpact: ScaleOfImpact[];
    impactedSystems: ImpactedSystem[];
    contributingRootCauses: IncidentRootCause[];
    other?: string;
    incidentImpactOther?: string;
    impactedSystemOther?: string;
    scaleOfImpactOther?: string;
    risksAndActions: IIncidentRisksAndActions;
    attachments: IAttachmentCollection[];
    notifiedPeople: string[];
    approvers: string[];
    approvalResponses: IApproval[];
    history: IIncidentHistory[];
    submitNotifications?: boolean;
    submitForApproval?: boolean;
    reopenComments: string[];
    equipmentCmmsAsset?: string;
    equipmentName?: string;
    equipmentModel?: string;
    equipmentSerial?: string;
    equipmentManufacturer?: string;
    equipmentAge?: string;
    localDateTime?: string;
    resolvedLocalDateTime?: string;
    finishLocalDateTime?: string;
}

export interface IApproval {
    approver: string;
    status: IncidentApprovalStatus;
    comment: string;
    statusDate: Date;
}

export interface IIncidentApproval extends IApproval {
    id: string;
    siteId: string;
}

export interface IIncidentRisksAndActions {
    risks: string[];
    actions: string[];
}

export interface IPersonSummary {
    id: string;
    fullName: string;
    firstName: string;
    lastName: string;
    email: string;
    siteRoles: string[];
    siteIds: string[];
    roles: string[];
}

export interface IIncidentHistory {
    id: string;
    changeDate: Date;
    status: IncidentStatus;
    details: string;
}

export interface IIncidentLessonsLearned {
    id: string;
    title: string;
    isCreated: boolean;
    createdDateTime?: Date;
}

export const newIncident: IIncident = {
    id: null,
    uid: null,
    title: '',
    siteId: '',
    severity: null,
    severityData: null,
    type: null,
    majorIncident: null,
    majorIncidentData: null,
    createdDateTime: new Date(),
    status: IncidentStatus.Draft,
    description: '',
    responsiblePeople: [],
    startDateTime: null,
    resolvedDateTime: null,
    finishDateTime: null,
    isBusinessImpacted: false,
    businessImpactDescription: undefined,
    operationalImpactDescription: '',
    hasLessonsLearned: false,
    lessonLearnDescription: '',
    lessonsLearned: {
        id: null,
        title: null,
        isCreated: false,
        createdDateTime: null,
    },
    rootCause: null,
    contributingFactors: [],
    incidentImpact: [],
    scaleOfImpact: [],
    impactedSystems: [],
    impactedSystemOther: '',
    incidentImpactOther: '',
    scaleOfImpactOther: '',
    other: '',
    risksAndActions: { risks: [], actions: [] },
    attachments: [],
    notifiedPeople: [],
    approvers: [],
    history: [],
    approvalResponses: [],
    contributingRootCauses: [],
    bucket: 'Incident',
    shard: 'Incident',
    eTag: '',
    reopenComments: [],
    localDateTime: null,
    resolvedLocalDateTime: null,
    finishLocalDateTime: null,
};
