import React from 'react';

const checkVersion = (latestVersion: any, currentVersion: any) => {
    if (latestVersion === currentVersion) {
        return false;
    }
    return true;
};

interface CacheBusterProps {
    children(props: CacheBusterState): JSX.Element | null;
}

export interface CacheBusterState {
    loading: boolean;
    isLatestVersion: boolean;
    refreshCacheAndReload(): any;
}

const clearCache = async () => {
    if (caches) {
        // Service worker cache should be cleared with caches.delete()
        const cacheNames = await caches.keys();
        for (const name of cacheNames) {
            await caches.delete(name);
        }
    }
};

class CacheBuster extends React.Component<CacheBusterProps, CacheBusterState> {
    public constructor(props: CacheBusterProps) {
        super(props);
        this.state = {
            loading: true,
            isLatestVersion: false,
            refreshCacheAndReload: async () => {
                console.log('Clearing old cache and reloading resources...');
                await clearCache();
                this.fetchLatestResources();
            },
        };
    }

    public componentDidMount() {
        if ('serviceWorker' in navigator && 'fetch' in window) {
            if (localStorage.getItem('appVersion') === null) {
                localStorage.setItem('appVersion', '0.1.0');
                this.fetchLatestResources();
            }

            fetch('/meta.json')
                .then((response) => response.json())
                .then((meta) => {
                    const latestVersion = meta.version;
                    const currentVersion = localStorage.getItem('appVersion');
                    const isHardRefresh = checkVersion(latestVersion, currentVersion);

                    if (isHardRefresh) {
                        console.log(
                            `We have a new version - ${latestVersion}. Should refresh resources`
                        );
                        this.setState({ loading: false, isLatestVersion: false });
                        localStorage.setItem('appVersion', latestVersion);
                    } else {
                        console.log(
                            `You already have the latest version - ${latestVersion}. No cache refresh needed.`
                        );
                        this.setState({ loading: false, isLatestVersion: true });
                    }
                });
        } else {
            this.setState({ loading: false, isLatestVersion: true });
        }
    }

    private async fetchLatestResources() {
        // Fetch and replace the updated resources programmatically
        // Example: Re-fetching the app's main JS and CSS files
        const links = document.querySelectorAll('link[rel="stylesheet"]');
        links.forEach((link) => {
            const element = link as HTMLLinkElement;
            const url = new URL(element.href);
            url.searchParams.set('v', new Date().getTime().toString());
            element.href = url.toString();
        });

        const scripts = document.querySelectorAll('script[src]');
        scripts.forEach((script) => {
            const element = script as HTMLScriptElement;
            const url = new URL(element.src);
            url.searchParams.set('v', new Date().getTime().toString());
            element.src = url.toString();
        });

        this.setState({ loading: false, isLatestVersion: true });
    }

    public render() {
        const { loading, isLatestVersion, refreshCacheAndReload } = this.state;
        return this.props.children({
            loading,
            isLatestVersion,
            refreshCacheAndReload,
        });
    }
}

export default CacheBuster;
