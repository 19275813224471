import * as React from 'react';
import { Loader } from 'components/loader';
import { ISiteAssessmentSummary, ISiteProfile } from 'models/site-profile';
import { Button, Toolbar, ToolbarGroupRight } from 'components/v2/components';
import { Formik, FormikProps } from 'formik';
import { IAttachmentQuery } from 'models/attachment/attachment-query';
import { Entity } from 'models/entity';
import { IAttachmentModel } from 'models/attachment/attachment-model';
import { ICermAssessmentSnapshot } from 'models/cerm/cerm-assessment-snapshot';
import { easeInScrollToTop } from 'utils/animation-utils';
import { CermAssessmentSummaryTabForm } from './cerm-assessment-summary-tab-form'
import { ConfirmNavigateAwayDialog } from 'components/v2/dialog/ConfirmNavigateAwayDialog';
import { object, string } from 'yup';
import useTranslate from 'translations/translation-utils';

interface IProps {
    isLoading: boolean;
    siteId: string;
    site: ISiteProfile;
    snapshots: ICermAssessmentSnapshot[];
    siteImage: IAttachmentModel[];
    loadAttachments: (query: IAttachmentQuery) => void;
    updateAssessmentSummary: (siteId: string, model: Partial<ISiteAssessmentSummary>) => void;
}

export const cermValidationSchema = object<Partial<ISiteAssessmentSummary>>().shape({
    facilityDescription: string().trim().nullable(),
    assessmentSummary: string().trim().nullable(),
    yearConstructed: string().trim().nullable(),
    criticalLinesOfBusiness: string().trim().nullable(),
    space: string().trim().nullable(),
    staff: string().trim().nullable(),
    contactInfo: string().trim().nullable(),
});

export const CermAssessmentSummaryTab: React.FC<IProps> = ({
    isLoading,
    siteId,
    site,
    snapshots,
    siteImage,
    loadAttachments,
    updateAssessmentSummary,
}) => {
    let formikBag: FormikProps<ISiteAssessmentSummary>;
    const [isFormDirty, setIsFormDirty] = React.useState(false);
    const translate = useTranslate();

    React.useEffect(() => {
        if (siteId) {
            loadAttachments({ entity: Entity.SiteImage, id: siteId, siteId });
        }
    }, [siteId]);

    const onSave = () => {
        formikBag.submitForm();
        easeInScrollToTop();
    };

    const renderTab = (formik: FormikProps<ISiteAssessmentSummary>) => {
        formikBag = formik;
        setIsFormDirty(formik.dirty);
        return (
            <CermAssessmentSummaryTabForm
                formikBag={formikBag}
                snapshots={snapshots}
                siteImage={siteImage}
                site={site}
            />
        );
    };

    const onSubmit = (value: Partial<ISiteAssessmentSummary>) => {
        setIsFormDirty(false);
        updateAssessmentSummary(siteId, value);
    };

    return (
        <div className="cerm-assessment-summary">
            <div className="tabs-container tab-content cerm-assessment-summary-tab">
                <Loader loading={isLoading}>
                    <Formik<Partial<ISiteAssessmentSummary>>
                        initialValues={site.assessmentSummary}
                        validationSchema={cermValidationSchema}
                        onSubmit={onSubmit}
                        render={renderTab}
                        enableReinitialize={true}
                        isInitialValid={true}
                    />

                    <ConfirmNavigateAwayDialog when={isFormDirty} />

                    <Toolbar type="save">
                        <ToolbarGroupRight>
                            <Button
                                id="btnSave"
                                type="submit"
                                buttonType="save"
                                onClick={onSave}
                                isLoading={isLoading}
                                disabled={!isFormDirty}
                            >
                                {translate('Globals.Label.Save')}
                            </Button>
                        </ToolbarGroupRight>
                    </Toolbar>
                </Loader>
            </div>
        </div>
    );
};
